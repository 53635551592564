import { CircularProgress } from '@mui/material';
import FirstPage from 'components/AdminCreateBrandBrief/FirstPage/FirstPage';
import SecondPage from 'components/AdminCreateBrandBrief/SecondPage/SecondPage';
import ThirdPage from 'components/AdminCreateBrandBrief/ThirdPage/ThirdPage';
import { UseGetBrandBriefById } from 'hooks';
import { FC, useLayoutEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

enum AdminBrandBriefsStages {
  First,
  Second,
  Third,
}

const AdminCreateBrandBrief: FC = () => {
  const [stage, setStage] = useState(0);
  const [searchParams] = useSearchParams();
  const { getBrief, data: brandBrief, loading } = UseGetBrandBriefById();

  useLayoutEffect(() => {
    const briefId = searchParams.get('briefId');
    if (!briefId) {
      return;
    }

    getBrief({ variables: { id: briefId } });
  }, [searchParams]);

  const stageNext = () => setStage((prevStage) => Math.min(prevStage + 1, 3));
  const stageBack = () => setStage((prevStage) => Math.max(prevStage - 1, 0));

  return !loading ? (
    <>
      {stage === AdminBrandBriefsStages.First && (
        <FirstPage brandBrief={brandBrief} stageNext={stageNext} />
      )}
      {stage === AdminBrandBriefsStages.Second && (
        <SecondPage
          brandBrief={brandBrief}
          stageNext={stageNext}
          stageBack={stageBack}
        />
      )}
      {stage === AdminBrandBriefsStages.Third && (
        <ThirdPage brandBrief={brandBrief} stageBack={stageBack} />
      )}
    </>
  ) : (
    <div className="w-full h-full flex justify-center items-center">
      <CircularProgress className="text-main-teal w-8 h-8" />
    </div>
  );
};

export default AdminCreateBrandBrief;
