import { CircularProgress } from '@mui/material';
import classNames from 'classnames';
import { StyledTextField } from 'components/TextField';
import { FC, useState } from 'react';

interface IEmailLoginProps {
  onLogin: (email: string) => Promise<void>;
  isLoading: boolean;
}

const EmailLogin: FC<IEmailLoginProps> = ({
  onLogin,
  isLoading,
}): JSX.Element => {
  const [email, setEmail] = useState('');

  return (
    <>
      <StyledTextField
        name="ids"
        label="Email Address"
        type="text"
        fullWidth={true}
        disabled={isLoading}
        onChange={(event) => {
          setEmail(event.target.value);
        }}
        InputLabelProps={{ className: 'text-black' }}
        InputProps={{
          className:
            'border-black [&>[type="text"]:focus]:border-black [&>fieldset]:border-black',
        }}
      />
      <button
        disabled={isLoading}
        onClick={() => onLogin(email)}
        className="bg-main-black text-white px-[22px] py-[4] w-[160px] h-[42px] rounded-[16px] text-[12px] disabled:bg-neutral-400 break-keep text-wrap font-[600] self-center"
      >
        <div className="flex justify-center gap-x-1 items-center relative">
          {isLoading ? (
            <CircularProgress
              className={classNames('w-4 h-4 text-white', {
                invisible: !isLoading,
              })}
            />
          ) : (
            <div>LOGIN</div>
          )}
        </div>
      </button>
    </>
  );
};

export default EmailLogin;
