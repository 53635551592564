import { FileDownload, OpenInNew, Search } from '@mui/icons-material';
import { CircularProgress, IconButton, InputAdornment } from '@mui/material';
import { GridRowParams, GridSortItem, GridSortModel } from '@mui/x-data-grid';
import {
  ADMIN_STATUS,
  CreativeRequest,
  CREATIVE_REQUEST_LAST_UPDATED_BY,
  EMAIL_TYPE,
  ENTITIES,
  SortOrder,
  USER_TYPES,
} from 'API';
import { downloadFileFromUrl } from 'components';
import { AdminApprovalContent } from 'components/adAdminApproval/Index';
import Modal from 'components/authentication/modal';
import { TABLE_HEADER_CELLS } from 'components/BrandCreativeRequests/constants';
import { StyledDataGrid } from 'components/DataGrid';
import { StyledTextField } from 'components/TextField';
import { UpdateCreativeRequest, UseSearchCreativeRequests } from 'hooks';
import { UseExportEntitiesToCSV } from 'hooks/query/useAdminActions';
import { useRequestStatusSendEmail } from 'hooks/query/useEmail';
import {
  DATA_GRID_ROW_OPTIONS,
  DEFAULT_DEBOUNCE_TIME_IN_MILLISECONDS,
} from 'hooks/utils';
import { debounce } from 'lodash';
import { FC, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ViewRequestProps, withRequestView } from 'state/requests';
import { AdminRoutes } from 'utils';
import { getSubmissionRowColor, TABLE_HEADER } from '../constants';

interface SortingPayload {
  sortKey: string;
  sortOrder: SortOrder;
}

interface ITableTabProps {
  reqloading: boolean;
  creativeRequestsData: CreativeRequest[];
}

const TableTab: FC<ITableTabProps & ViewRequestProps> = ({ getVideoLink }) => {
  const [filteredData, setFilteredData] = useState<CreativeRequest[]>([]);
  const [showInspiration, setShowInspiration] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [selectedCreativeRequest, setSelectedCreativeRequest] =
    useState<CreativeRequest | null>(null);
  const [search, setSearch] = useState<string | null>(null);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [sortingModel, setSortingModel] = useState<SortingPayload>({
    sortKey: 'updatedAt',
    sortOrder: SortOrder.desc,
  });

  const { updateRequest, loading: updateCreativeRequestLoading } =
    UpdateCreativeRequest();
  const { sendEmailData, loading: sendStatusEmailLoading } =
    useRequestStatusSendEmail();
  const navigate = useNavigate();
  const {
    exportEntities,
    url: exportUrl,
    loading: exportEntitiesLoading,
  } = UseExportEntitiesToCSV();
  const {
    searchRequests,
    requests,
    totalItems,
    loading: searchLoading,
  } = UseSearchCreativeRequests();
  const isDetailsViewOpen = useMemo(() => {
    const location = new URL(window.location.href);
    return !!location.searchParams.get('content');
  }, [window.location.href]);

  const getCreativeRequests = ({
    page,
    pageSize,
    search,
    sortKey,
    sortOrder,
  }: {
    page?: number | null;
    pageSize?: number | null;
    search?: string | null;
    sortKey?: string | null;
    sortOrder?: SortOrder | null;
  }) => {
    searchRequests({
      variables: {
        search,
        page: page || 0,
        pageSize: pageSize || 10,
        sortKey: sortKey || 'updatedAt',
        sortOrder: sortOrder || SortOrder.desc,
      },
      fetchPolicy: 'network-only',
      errorPolicy: 'ignore',
    });
  };

  useLayoutEffect(() => {
    getCreativeRequests({
      ...paginationModel,
      ...sortingModel,
      search,
    });
  }, []);

  useEffect(() => {
    setFilteredData((requests as unknown as CreativeRequest[]) || []);
  }, [requests]);

  useEffect(() => {
    if (exportUrl) {
      downloadFileFromUrl(exportUrl);
    }
  }, [exportUrl]);

  const updateCreativeRequestStatus = async (
    newStatus: ADMIN_STATUS,
    comment?: string,
    sendEmail?: boolean
  ) => {
    if (!selectedCreativeRequest) {
      return;
    }

    const isApproved = newStatus === ADMIN_STATUS.Approved;
    const adminComment = [
      ...(selectedCreativeRequest.adminComment || []),
      comment || '',
    ];

    await updateRequest({
      variables: {
        input: {
          id: selectedCreativeRequest.id,
          adminApproval: ADMIN_STATUS[newStatus],
          lastUpdatedBy: CREATIVE_REQUEST_LAST_UPDATED_BY.ADMIN,
          ...(!isApproved && { adminComment }),
        },
      },
      errorPolicy: 'ignore',
    });

    if (sendEmail) {
      // As most of ADMIN_STATUS and EMAIL_TYPE matches I don't add new status parameter, but checked with the following case.
      const emailTypeByAdminStatus = isApproved
        ? EMAIL_TYPE.ADMIN_APPROVED
        : ADMIN_STATUS[newStatus].toUpperCase();

      await sendEmailData({
        variables: {
          creativeRequestUrl: `creatives?id=${selectedCreativeRequest.id}`,
          email: selectedCreativeRequest.email,
          name: selectedCreativeRequest?.creatorProfile?.name,
          brandBriefName: selectedCreativeRequest.BriefName,
          creativeUniqueId: selectedCreativeRequest.uniqueId,
          emailType: emailTypeByAdminStatus,
          brandBriefId: selectedCreativeRequest.brandBriefId,
          feedback: comment || '',
        },
      });
    }

    setShowSuccessModal(true);
    onSuccess();
  };

  const tableHead = useMemo(
    () =>
      TABLE_HEADER.map((c) => {
        switch (c.field) {
          case 'details':
            return {
              ...c,
              renderCell: (params) => (
                <div className="h-full flex justify-center items-center">
                  <IconButton
                    className="w-[25px] h-[25px] p-[20px]"
                    onClick={() => {
                      onRowClick(params.row);
                    }}
                  >
                    <OpenInNew />
                  </IconButton>
                </div>
              ),
            };
          default:
            return c;
        }
      }),
    [TABLE_HEADER_CELLS]
  );

  const onRowClick = (req: CreativeRequest) => {
    navigate(`${AdminRoutes.CreativeApproval}?content=true`);
    setSelectedCreativeRequest(req);
    setShowInspiration(true);
  };

  const onSortModelChange = (model?: GridSortItem) => {
    if (!model) {
      return;
    }

    const updatedSortModel = {
      sortKey: model.field || sortingModel.sortKey,
      sortOrder: (model.sort || sortingModel.sortOrder) as SortOrder,
    };

    setSortingModel(updatedSortModel);
    getCreativeRequests({
      page: paginationModel.page,
      pageSize: paginationModel.pageSize,
      search: search,
      ...updatedSortModel,
    });
  };

  const onSearch = debounce((text: string) => {
    const search = text.trim();

    setPaginationModel((prev) => ({ page: 0, pageSize: prev.pageSize }));
    setSortingModel({ sortKey: 'updatedAt', sortOrder: SortOrder.desc });
    getCreativeRequests({ search });
    setSearch(search);
  }, DEFAULT_DEBOUNCE_TIME_IN_MILLISECONDS);

  const onExport = async () => {
    await exportEntities({ variables: { entity: ENTITIES.CreativeRequest } });
  };

  const handlePaginationModelChange = (newPaginationModel) => {
    if (searchLoading) {
      return;
    }

    const updatedPaginationModel = {
      page:
        newPaginationModel.pageSize !== paginationModel.pageSize
          ? 0
          : newPaginationModel.page,
      pageSize: newPaginationModel.pageSize,
    };

    setPaginationModel(updatedPaginationModel);
    getCreativeRequests({
      ...updatedPaginationModel,
      search,
      sortKey: sortingModel.sortKey,
      sortOrder: sortingModel.sortOrder,
    });
  };

  const onSuccess = async () => {
    getCreativeRequests({
      ...paginationModel,
      ...sortingModel,
      search,
    });
  };

  return showInspiration && selectedCreativeRequest && isDetailsViewOpen ? (
    <>
      {/* @ts-ignore  FIXME: */}
      <AdminApprovalContent
        videoUrl={selectedCreativeRequest?.tiktokCreativeUrl || ''}
        onClose={() => setShowInspiration(false)}
        request={selectedCreativeRequest}
        getVideoLink={getVideoLink}
        createAdPayload={{}}
        onSuccess={onSuccess}
        updateCreativeRequestStatus={updateCreativeRequestStatus}
        type="Admin"
        reqLoading={updateCreativeRequestLoading || sendStatusEmailLoading}
      />
      <Modal
        isOpen={showSuccessModal}
        handleClose={() => {
          setShowInspiration(false);
          setShowSuccessModal(false);
        }}
        type="brand"
        content="The Status of the creative request was successfully changed"
      />
    </>
  ) : (
    <div className="md:col-span-2">
      <div className="md:flex items-center mb-4">
        <section className="flex gap-4 w-full items-center justify-between mt-6">
          <div>
            <StyledTextField
              variant="standard"
              placeholder="Search..."
              onChange={(event) => {
                onSearch(event?.target.value);
              }}
              fullWidth={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <div>
            <button
              className="bg-main-black text-white px-[22px] py-[4] w-[200px] h-[42px] rounded-[16px] text-[12px] disabled:bg-neutral-400 break-keep text-wrap"
              disabled={!requests?.length}
              onClick={onExport}
            >
              <div className="flex justify-evenly uppercase font-semibold items-center gap-x-1">
                {exportEntitiesLoading ? (
                  <CircularProgress className="text-white w-6 h-6" />
                ) : (
                  <>
                    <FileDownload />
                    <span>Export Creative Requests</span>
                  </>
                )}
              </div>
            </button>
          </div>
        </section>
      </div>

      <div className="overflow-x-auto max-w-full">
        <StyledDataGrid
          rows={filteredData || []}
          sx={{ minHeight: 600 }}
          density="comfortable"
          columns={tableHead}
          pageSizeOptions={DATA_GRID_ROW_OPTIONS}
          loading={searchLoading}
          onPaginationModelChange={handlePaginationModelChange}
          paginationModel={paginationModel}
          onSortModelChange={(model: GridSortModel) => {
            onSortModelChange(model?.[0]);
          }}
          rowCount={totalItems || 0}
          autosizeOptions={{
            expand: true,
            includeHeaders: true,
            includeOutliers: true,
          }}
          getRowClassName={(params) => {
            // Highlight the last updated submission so admin user can review it.
            const lastUpdatedBy = params.row.lastUpdatedBy;
            const adminApproval = params.row.adminApproval;

            return getSubmissionRowColor({
              lastUpdatedBy,
              adminApproval,
              userType: USER_TYPES.ADMIN_USER,
            });
          }}
          onRowClick={({ row }: GridRowParams) => {
            onRowClick(row);
          }}
          sortingMode="server"
          paginationMode="server"
          disableColumnResize={true}
          disableRowSelectionOnClick={true}
          disableAutosize={true}
          disableColumnMenu={true}
          autosizeOnMount={true}
          disableColumnSelector={true}
        />
      </div>
    </div>
  );
};

export default withRequestView(TableTab);
