import { Check, Close, FlagOutlined, Visibility } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { BrandBrief } from 'API';
import { EditWithPencilIcon, IIcon, OpenFolderIcon } from 'assets/icons/icons';
import { FC, ReactNode, useMemo } from 'react';
import InformationCard from '../InformationCard/InformationCard';

export interface IInformationDisplayProps {
  brandBrief?: BrandBrief;
}

const getPractices = (practices: string[], icon: ReactNode) => (
  <>
    {practices.map((p) => (
      <div key={p} className="flex items-center gap-x-2 break-all">
        {icon}

        <p>{p}</p>
      </div>
    ))}
  </>
);

const getBriefTags = (tags: string[]) =>
  tags?.map((t) => (
    <div
      className="bg-[#1D1C1C] text-[#fff] text-[12px] rounded-[16px] py-1 px-4"
      key={t}
    >
      {t}
    </div>
  ));

const InformationDisplay: FC<IInformationDisplayProps> = ({ brandBrief }) => {
  const bestPractices = useMemo(
    () =>
      getPractices(brandBrief?.goodPractices || [], <Check fontSize="small" />),
    [brandBrief?.goodPractices]
  );

  const badPractices = useMemo(
    () =>
      getPractices(
        [
          ...(brandBrief?.badPractices || []),
          ...(brandBrief?.commonRejectionReasons || []),
        ],
        <Close fontSize="small" />
      ),
    [brandBrief?.badPractices, brandBrief?.commonRejectionReasons]
  );

  const maybes = useMemo(
    () => getPractices(brandBrief?.maybes || [], <IIcon fontSize="small" />),
    [brandBrief?.maybes]
  );

  const tags = useMemo(
    () => getBriefTags(brandBrief?.tags || []),
    [brandBrief?.tags]
  );

  return (
    <div className="flex flex-col gap-y-4">
      <Typography className="uppercase font-oswald font-[600] text-[20px]">
        The important stuff
      </Typography>

      <div className="flex flex-col gap-y-[30px]">
        <InformationCard
          icon={<OpenFolderIcon />}
          title="The activation overview"
        >
          <div className="flex flex-col gap-y-4">
            <Typography className="break-all h-[100px] overflow-y-auto whitespace-pre-line">
              {brandBrief?.overview || brandBrief?.brandBriefDetails}
            </Typography>

            <div className="flex gap-x-2 items-center">
              <Typography className="uppercase font-oswald font-[600]">
                Tags
              </Typography>

              <div className="flex flex-wrap gap-2 ml-11">{tags}</div>
            </div>

            <div className="flex gap-x-4">
              <Typography className="uppercase font-oswald font-[600]">
                Objective
              </Typography>

              <Typography className="break-all h-[100px] overflow-y-auto">
                {brandBrief?.objective}
              </Typography>
            </div>
          </div>
        </InformationCard>

        <InformationCard
          cardWrapperClassnames="h-[200px]"
          icon={<EditWithPencilIcon />}
          title="Messaging"
        >
          <p className="flex flex-col break-all overflow-y-auto whitespace-pre-line">
            {brandBrief?.messaging}
          </p>
        </InformationCard>

        <InformationCard
          cardWrapperClassnames="mt-[55px]"
          icon={<Visibility />}
          title="DOS, DON'TS & MAYBES"
        >
          <div className="flex flex-col justify-between space-y-4">
            <div className="flex flex-col gap-y-2">
              <Typography className="font-[700]">Must include</Typography>

              {bestPractices}
            </div>

            <div className="flex flex-col gap-y-2">
              <Typography className="font-[700]">Must exclude</Typography>

              {badPractices}
            </div>

            <div className="flex flex-col gap-y-2">
              <Typography className="font-[700]">Maybes</Typography>

              {maybes}
            </div>
          </div>
        </InformationCard>

        <InformationCard title="Tone" icon={<FlagOutlined />}>
          <Typography className="break-all max-h-[100px] overflow-y-auto">
            {brandBrief?.tone}
          </Typography>
        </InformationCard>
      </div>
    </div>
  );
};

export default InformationDisplay;
