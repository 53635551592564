import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Box, Typography } from '@mui/material';
import { useCallback } from 'react';
import { Accept, useDropzone } from 'react-dropzone';

interface IFileUploadProps {
  accept?: Accept;
  onFileSelect: (file) => Promise<void>;
  emptyMessage?: string;
  label?: string;
}

const FileUpload = ({
  accept,
  onFileSelect,
  emptyMessage,
  label = ' Click to upload files',
}: IFileUploadProps) => {
  const onDrop = useCallback(onFileSelect, [onFileSelect]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
  });

  return (
    <Box
      {...getRootProps()}
      sx={{
        border: '2px dashed grey',
        borderRadius: '10px',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
        backgroundColor: isDragActive ? '#f0f0f0' : '#ffffff',
        maxWidth: '100%',
      }}
    >
      <input {...getInputProps()} onChange={onFileSelect} />
      <div className="flex flex-col gap-5 items-center">
        <UploadFileIcon sx={{ color: '#60a5fa' }} />

        <Typography variant="h6" gutterBottom>
          {isDragActive ? (
            <p className=" text-[16px]">Drop the files here ...</p>
          ) : (
            <span className="flex justify-center items-center gap-1">
              <p className="text-blue-400 underline text-[16px]">{label}</p>
              <p className=" text-[14px]">or drag and drop</p>
            </span>
          )}
        </Typography>

        <Typography fontWeight={400} fontSize={14}>
          {emptyMessage || 'SVG, PNG, JPG or GIF (max. 3MB)'}
        </Typography>
      </div>
    </Box>
  );
};

export default FileUpload;
