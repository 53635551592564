import { ApprovedAds } from '../../API';

type filterAggregator = {
  cache: Record<string, boolean>;
  result: ApprovedAds[];
};

export const filterDuplicate = (duplicateArray: any[]): ApprovedAds[] => {
  const { result } = duplicateArray.reduce(
    (aggr, val) => {
      if (aggr.cache[String(val?.ad_id)]) {
        return aggr;
      }

      return {
        result: [...aggr.result, val],
        cache: {
          ...aggr.cache,
          [String(val?.ad_id)]: true,
        },
      };
    },
    {
      cache: {},
      result: [],
    } as filterAggregator
  );

  return result;
};
