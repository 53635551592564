import { BrandProfile, CreateBrandProfileInput } from 'API';
import { createUserBrand, updateUserBrand } from 'hooks';
import withApolloProvider from 'hooks/apollo/withApollo';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'state/auth';
import { ProfileContext } from 'state/profileSteps';
import { BrandProps } from './brand.interface';

export function withBrand<T>(Component: React.FC<T & BrandProps>): React.FC<T> {
  return withApolloProvider((props: T) => {
    const {
      createBrand,
      loading: createBrandLoading,
      brand: createBrandRes,
    } = createUserBrand();
    const {
      updateBrand,
      loading: updateBrandLoading,
      brand: updateBrandRes,
    } = updateUserBrand();
    const {
      authState: { email },
    } = useContext(AuthContext);
    const { profileState, setProfileState } = useContext(ProfileContext);
    const {
      profileState: { data: profileData },
    } = useContext(ProfileContext);
    const [brandState, setBrandState] = useState<BrandProfile>();

    const updateData = (data: CreateBrandProfileInput): void => {
      if (email && profileData?.id) {
        const input = {
          ...data,
          userProfileBrandId: profileData.id,
          userEmail: email,
        };

        if (data.id) {
          const brandData = JSON.parse(JSON.stringify(input));
          delete brandData['briefs'];
          delete brandData['__typename'];
          delete brandData['createdAt'];
          delete brandData['updatedAt'];

          updateBrand({
            variables: { input: brandData },
            errorPolicy: 'ignore',
          });
        } else createBrand({ variables: { input }, errorPolicy: 'ignore' });
      }
    };

    useEffect(() => {
      if (!createBrandLoading && createBrandRes) setBrandState(createBrandRes);
    }, [createBrandRes, createBrandLoading]);

    useEffect(() => {
      if (!updateBrandLoading && updateBrandRes) setBrandState(updateBrandRes);
    }, [updateBrandRes, updateBrandLoading]);

    useEffect(() => {
      if (brandState && profileState.data?.brand)
        setProfileState({
          ...profileState,
          data: {
            ...profileState?.data,
            brand: { ...profileState.data?.brand, items: [brandState] },
          },
        });
    }, [brandState]);

    const hocProps = {
      brandLoading: updateBrandLoading || createBrandLoading,
      updateData,
      data: brandState,
      profileState,
    };
    return <Component {...props} {...hocProps} />;
  });
}
export default withBrand;
