import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import React, { useState } from 'react';
import { MAX_PAGINATION_PER_PAGE } from '../../hooks/usePagination';

interface PaginationProps {
  total: number;
  onPageChange: (page: number) => void;
  currentPage?: number;
  perPage?: number;
}

const Pagination: React.FC<PaginationProps> = ({
  total,
  onPageChange,
  perPage = MAX_PAGINATION_PER_PAGE,
  currentPage = 1,
}) => {
  const [activePage, setActivePage] = useState<number>(currentPage);
  const totalPages = Math.ceil(total / perPage);
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  const handlePageClick = (page: number) => {
    setActivePage(page);
    onPageChange(page);
  };

  const handlePrevClick = () => {
    if (activePage > 1) {
      handlePageClick(activePage - 1);
    }
  };

  const handleNextClick = () => {
    if (activePage < totalPages) {
      handlePageClick(activePage + 1);
    }
  };

  return (
    <div className="flex justify-center items-center space-x-2 mt-4">
      <button
        type="button"
        disabled={activePage === 1}
        onClick={handlePrevClick}
        className={`py-2 rounded-md ${
          activePage === 1 ? 'cursor-not-allowed opacity-20' : 'text-gray-700'
        }`}
      >
        <ChevronLeft style={{ color: 'gray' }} />
      </button>

      {pageNumbers.map((page) => (
        <button
          type="button"
          key={page}
          className={`py-2 rounded-md ${
            activePage === page ? 'text-gray-300' : 'text-gray-700'
          }`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ))}

      <button
        type="button"
        disabled={activePage === totalPages}
        onClick={handleNextClick}
        className={`py-2 rounded-md ${
          activePage === totalPages
            ? 'cursor-not-allowed opacity-20'
            : 'border-gray-300 text-gray-700'
        }`}
      >
        <ChevronRight style={{ color: 'gray' }} />
      </button>
    </div>
  );
};

export default Pagination;
