export interface IFilesUploadState {
  [fileName: string]: IFileUploadState;
}

export enum FileUploadStatus {
  Loading = 'Loading',
  Complete = 'Complete',
  Failed = 'Failed',
}

export interface IFileUploadState {
  progress: number;
  status: FileUploadStatus;
  fileSize: number;
  fileName: string;
  fileKey: string;
  hasError?: boolean;
  onDelete: () => void;
}
