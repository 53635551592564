import { useMutation } from '@apollo/client';
import {
  GetFacebookAdSetsMutation,
  GetFacebookAdSetsMutationVariables,
  GetFacebookCampaignMutation,
  GetFacebookCampaignMutationVariables,
} from 'API';
import { getFacebookAdSets, getFacebookCampaign } from 'graphql/mutations';
import { getQuery } from 'hooks/utils';

const parseMETAStringToJSON = (str?: string | null) => {
  let resultData;
  try {
    if (str != null) {
      const { data } = JSON.parse(resultData);
      resultData = data?.list || [];
    }

    resultData = [];
  } catch (error) {
    resultData = [];
  }

  return resultData;
};

export const UseGetMetaCampaigns = () => {
  const [getMetaCampaignList, { data, loading, error }] = useMutation<
    GetFacebookCampaignMutation,
    GetFacebookCampaignMutationVariables
  >(getQuery(getFacebookCampaign));

  return {
    loading,
    getMetaCampaignList,
    error,
    data: parseMETAStringToJSON(data?.getFacebookCampaign),
  };
};

export const UseGetMetaAdGroups = () => {
  const [getMetaAdGroupList, { data, loading, error }] = useMutation<
    GetFacebookAdSetsMutation,
    GetFacebookAdSetsMutationVariables
  >(getQuery(getFacebookAdSets));

  return {
    loading,
    getMetaAdGroupList,
    error,
    data: parseMETAStringToJSON(data?.getFacebookAdSets),
  };
};
