import { useLazyQuery, useMutation } from '@apollo/client';
import {
    CreateUserProfileMutation,
    CreateUserProfileMutationVariables,
    GetCreativeEarningsQuery,
    GetCreativeEarningsQueryVariables,
    GetUserProfileQuery,
    GetUserProfileQueryVariables,
    LinkCreatorInstagramAccountMutation,
    LinkCreatorInstagramAccountMutationVariables,
    LinkCreatorTikTokAccountMutation,
    LinkCreatorTikTokAccountMutationVariables,
    LinkCreatorYoutubeAccountMutation,
    LinkCreatorYoutubeAccountMutationVariables,
    LinkFacebookAccountMutation,
    LinkFacebookAccountMutationVariables,
    LinkTiktokAccountMutation,
    LinkTiktokAccountMutationVariables,
    LinkUserTypeMutation,
    LinkUserTypeMutationVariables,
    LinkYoutubeAccountMutation,
    LinkYoutubeAccountMutationVariables,
    ListUserProfilesQuery,
    ListUserProfilesQueryVariables,
    UpdateUserProfileMutation,
    UpdateUserProfileMutationVariables,
    ValidateTiktokAccessMutation,
    ValidateTiktokAccessMutationVariables,
    ListCreativeRequestEarningsQuery, ListCreativeRequestEarningsQueryVariables
} from 'API';
import {
    createUserProfile as createUserQuery,
    linkCreatorInstagramAccount,
    linkCreatorTikTokAccount,
    linkCreatorYoutubeAccount,
    linkFacebookAccount,
    linkTiktokAccount,
    linkUserType,
    linkYoutubeAccount,
    updateUserProfile,
    validateTiktokAccess,
} from 'graphql/mutations';
import {
    getCreativeEarnings,
    getUserProfile as getUserProfileQuery, listCreativeRequestEarnings,
    listUserProfiles
} from 'graphql/queries';
import {
    CreateUserProfileProps,
    GetUserProfileProps,
    ILinkFacebookAccountResponse,
    ILinkInstagramCreatorAccountResponse,
    ILinkTiktokAccountResponse,
    ILinkTikTokCreatorAccountResponse,
    ILinkYoutubeAccountResponse,
    ILinkYoutubeCreatorAccountResponse,
    IValidateTiktokAccessTokenResponse,
    UpdateUserProfileProps,
    UserTypeSetterProps,
} from 'hooks/utils';
import { getQuery } from 'hooks/utils/helpers';

export const getUserProfile = (): GetUserProfileProps => {
    const [getProfile, { data, loading, error }] = useLazyQuery<
        GetUserProfileQuery,
        GetUserProfileQueryVariables
    >(getQuery(getUserProfileQuery));
    const profileData = data?.getUserProfile || null;
    const errorData =
        error || (profileData ? undefined : new Error('No User Found'));
    return {
        loading,
        getProfile,
        profileData,
        isProfileExists: !!profileData,
        error: errorData,
    };
};

export const createUserProfile = (): CreateUserProfileProps => {
    const [createProfile, { data, loading, error }] = useMutation<
        CreateUserProfileMutation,
        CreateUserProfileMutationVariables
    >(getQuery(createUserQuery));
    const profileData = data?.createUserProfile || null;
    const errorData =
        error || (profileData ? undefined : new Error('No User Found'));
    return { loading, createProfile, profileData, error: errorData };
};

export const useUserTypeSetter = (): UserTypeSetterProps => {
    const [setUserType, { data, loading, error }] = useMutation<
        LinkUserTypeMutation,
        LinkUserTypeMutationVariables
    >(getQuery(linkUserType));
    return { loading, setUserType, data, error };
};

export const UseUpdateUserProfile = (): UpdateUserProfileProps => {
    const [updateProfile, { data, loading, error }] = useMutation<
        UpdateUserProfileMutation,
        UpdateUserProfileMutationVariables
    >(getQuery(updateUserProfile), {
        errorPolicy: 'ignore',
    });
    const profileData = data?.updateUserProfile || null;
    const errorData =
        error || (profileData ? undefined : new Error('No User Found'));
    return { loading, updateProfile, profileData, error: errorData };
};

export const UseGetCreativeEarnings = () => {
    const [getEarnings, { data, loading, error }] = useLazyQuery<
        GetCreativeEarningsQuery,
        GetCreativeEarningsQueryVariables
    >(getQuery(getCreativeEarnings));

    return {
        getEarnings,
        data: data?.getCreativeEarnings?.items || [],
        loading,
        error,
    };
};

export const UseListCreativeRequestEarnings = (creatorId) => {
    const [getAllEarnings, { data, loading, error }] = useLazyQuery<
        ListCreativeRequestEarningsQuery,
        ListCreativeRequestEarningsQueryVariables
    >(getQuery(listCreativeRequestEarnings), {
        variables: {
            creatorId: creatorId,  // Directly passing creatorId as a variable
        },
    });

    // Helper function to validate dates
    const isValidDate = (dateStr) => {
        return dateStr && typeof dateStr === 'string' && !isNaN(new Date(dateStr).getTime());
    };

    // Filter invalid dates after data retrieval
    const allEarningsData = (data?.listCreativeRequestEarnings?.items || []).reduce((filtered, item) => {
        // Check if both dates are valid, otherwise exclude the item
        if (isValidDate(item?.fromDate) && isValidDate(item?.toDate)) {
            filtered.push(item);
        }
        return filtered;
    }, []);



    return {
        getAllEarnings,
        allEarningsData,
        loading,
        error,
    };
};

export const useLinkTiktokAccount = (): ILinkTiktokAccountResponse => {
    const [linkTiktok, { data, loading, error }] = useMutation<
        LinkTiktokAccountMutation,
        LinkTiktokAccountMutationVariables
    >(getQuery(linkTiktokAccount));
    return {
        loading,
        linkTiktok,
        data: data?.linkTiktokAccount,
        error,
    };
};

export const useLinkFacebookAccount = (): ILinkFacebookAccountResponse => {
    const [linkFacebook, { data, loading, error }] = useMutation<
        LinkFacebookAccountMutation,
        LinkFacebookAccountMutationVariables
    >(getQuery(linkFacebookAccount));
    return {
        loading,
        linkFacebook,
        data: data?.linkFacebookAccount,
        error,
    };
};

export const useLinkYoutubeAccount = (): ILinkYoutubeAccountResponse => {
    const [linkYoutube, { data, loading, error }] = useMutation<
        LinkYoutubeAccountMutation,
        LinkYoutubeAccountMutationVariables
    >(getQuery(linkYoutubeAccount));
    return {
        loading,
        linkYoutube,
        data: data?.linkYoutubeAccount,
        error,
    };
};

export const useLinkYoutubeCreatorAccount =
    (): ILinkYoutubeCreatorAccountResponse => {
        const [linkYoutubeCreatorAccount, { data, loading, error }] = useMutation<
            LinkCreatorYoutubeAccountMutation,
            LinkCreatorYoutubeAccountMutationVariables
        >(getQuery(linkCreatorYoutubeAccount));
        return {
            loading,
            linkYoutubeCreatorAccount,
            data: data?.linkCreatorYoutubeAccount,
            error,
        };
    };

export const useLinkTikTokCreatorAccount =
    (): ILinkTikTokCreatorAccountResponse => {
        const [linkTikTokCreatorAccount, { data, loading, error }] = useMutation<
            LinkCreatorTikTokAccountMutation,
            LinkCreatorTikTokAccountMutationVariables
        >(getQuery(linkCreatorTikTokAccount));
        return {
            loading,
            linkTikTokCreatorAccount,
            data: data?.linkCreatorTikTokAccount,
            error,
        };
    };

export const useLinkInstagramCreatorAccount =
    (): ILinkInstagramCreatorAccountResponse => {
        const [linkInstagramCreatorAccount, { data, loading, error }] = useMutation<
            LinkCreatorInstagramAccountMutation,
            LinkCreatorInstagramAccountMutationVariables
        >(getQuery(linkCreatorInstagramAccount));
        return {
            loading,
            linkInstagramCreatorAccount,
            data: data?.linkCreatorInstagramAccount,
            error,
        };
    };

export const useValidateTiktokAccessToken =
    (): IValidateTiktokAccessTokenResponse => {
        const [validateTiktokAccessToken, { data, loading, error }] = useMutation<
            ValidateTiktokAccessMutation,
            ValidateTiktokAccessMutationVariables
        >(getQuery(validateTiktokAccess));
        return {
            loading,
            validateTiktokAccessToken,
            data: data?.validateTiktokAccess,
            error,
        };
    };

export const UseListUserProfiles = () => {
    const [listProfiles, { data, error, loading }] = useLazyQuery<
        ListUserProfilesQuery,
        ListUserProfilesQueryVariables
    >(getQuery(listUserProfiles));

    return { listProfiles, data, error, loading };
};

export const UseGetUserProfile = () => {
    const [getProfile, { data, error, loading }] = useLazyQuery<
        GetUserProfileQuery,
        GetUserProfileQueryVariables
    >(getQuery(getUserProfileQuery));

    return { getProfile, data, error, loading };
};
