import { useLazyQuery, useMutation } from '@apollo/client';
import {
  BrandProfilesByUserEmailQuery,
  BrandProfilesByUserEmailQueryVariables,
  CreateBrandProfileMutation,
  CreateBrandProfileMutationVariables,
  GetBrandProfileQuery,
  GetBrandProfileQueryVariables,
  ModelAttributeTypes,
  UpdateBrandProfileMutation,
  UpdateBrandProfileMutationVariables,
  UserProfilesByUserTypeQuery,
  UserProfilesByUserTypeQueryVariables,
  USER_TYPES,
} from 'API';
import { createBrandProfile, updateBrandProfile } from 'graphql/mutations';
import {
  brandProfilesByUserEmail,
  getBrandProfile,
  userProfilesByUserType,
} from 'graphql/queries';
import { CreateBrandProps, UpdateBrandProps } from 'hooks/utils';
import { getQuery } from 'hooks/utils/helpers';

export const createUserBrand = (): CreateBrandProps => {
  const [createBrand, { data, loading, error }] = useMutation<
    CreateBrandProfileMutation,
    CreateBrandProfileMutationVariables
  >(getQuery(createBrandProfile));

  const brand = data?.createBrandProfile || null;
  const errorData =
    error || (brand ? undefined : new Error('No Brand Created'));
  return { loading, createBrand, brand, error: errorData };
};

export const UseCreateBrandProfile = () => {
  const [createBrand, { data, loading, error }] = useMutation<
    CreateBrandProfileMutation,
    CreateBrandProfileMutationVariables
  >(getQuery(createBrandProfile));

  return { createBrand, data, loading, error };
};

export const UseGetBrandProfileByUserEmail = () => {
  const [getBrand, { data, error, loading }] = useLazyQuery<
    BrandProfilesByUserEmailQuery,
    BrandProfilesByUserEmailQueryVariables
  >(getQuery(brandProfilesByUserEmail));

  return { getBrand, data, error, loading };
};

export const updateUserBrand = (): UpdateBrandProps => {
  const [updateBrand, { data, loading, error }] = useMutation<
    UpdateBrandProfileMutation,
    UpdateBrandProfileMutationVariables
  >(getQuery(updateBrandProfile), { errorPolicy: 'ignore' });
  const brand = data?.updateBrandProfile || null;
  const errorData = error || (brand ? undefined : new Error('No User Found'));
  return { loading, updateBrand, brand, error: errorData };
};

export const useGetBrandProfile = () => {
  const [getBrand, { data, loading, error }] = useLazyQuery<
    GetBrandProfileQuery,
    GetBrandProfileQueryVariables
  >(getQuery(getBrandProfile));
  const brandData = data?.getBrandProfile
    ? { ...data?.getBrandProfile, brand: undefined }
    : null;
  const errorData =
    error || (brandData ? undefined : new Error('No User Found'));
  return {
    loading,
    getBrand,
    brandData,
    error: errorData,
  };
};

export const UseGetBrandOptions = () => {
  const [getBrandOptions, { data, error, loading }] = useLazyQuery<
    UserProfilesByUserTypeQuery,
    UserProfilesByUserTypeQueryVariables
  >(getQuery(userProfilesByUserType), {
    variables: {
      userType: USER_TYPES.BRAND_USER,
      limit: 10000,
      filter: {
        name: {
          attributeExists: true,
          attributeType: ModelAttributeTypes.string,
        },
      },
    },
    errorPolicy: 'ignore',
  });

  const formattedData = (data?.userProfilesByUserType?.items || [])
    .filter((bp) => bp?.name && bp.name.length > 1)
    .map((bp) => ({
      id: bp?.id || '',
      name: bp?.name || '',
    }))
    .toSorted((a, b) => a.name.localeCompare(b.name));

  return { getBrandOptions, data: formattedData, error, loading };
};
