import { Help } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import { FC, ReactElement, ReactNode } from 'react';

export interface IInformationCardProps {
    icon?: ReactNode;
    title?: string;
    hasTooltip?: boolean;
    tooltipMessage?: string;
    children?: ReactElement;
    cardWrapperClassnames?: string
}

const InformationCard: FC<IInformationCardProps> = ({
    icon,
    title,
    hasTooltip,
    children,
    cardWrapperClassnames,
}) => {
    return (
        <div className={`border-1 border-gray-200 rounded-3xl flex flex-col p-[20px] gap-y-4 ${cardWrapperClassnames}`}>
            <div className="flex justify-between items-center">
                <div className="flex items-center gap-x-3">
                    {icon}

                    <Typography className="uppercase font-oswald font-[600] text-[16px]">
                        {title}
                    </Typography>
                </div>

                {hasTooltip && (
                    <IconButton>
                        <Help />
                    </IconButton>
                )}
            </div>

            {children}
        </div>
    );
};

export default InformationCard;
