import ShouldRender from 'components/shouldRender';
import React, { FC } from 'react';

const ACCEPTED_FILE_TYPES = '.png, .jpg';

export interface IImageInputProps {
  label: string;
  id: string;
  error?: string | null;
  imageURL?: string | null;
  accept?: string | null;
  type: 'passport' | 'letter' | 'proofOfAddress';
  handleChange: (event, type: 'passport' | 'letter' | 'proofOfAddress') => void;
}

const ImageInput: FC<IImageInputProps> = ({
  error,
  type,
  label,
  id,
  handleChange,
  imageURL,
  accept = ACCEPTED_FILE_TYPES,
}) => {
  const [displayImage, setDisplayImage] = React.useState<
    string | null | undefined
  >(imageURL);

  return (
    <div className="brand-dashboard__profile-group">
      <div className="brand-dashboard__profile-label text-black text-left font-[400] text-[14px]">
        {label}
      </div>

      <div className="field-label-container">
        <label
          htmlFor={id}
          className={`upload-button ${
            displayImage ? 'border-transparent bg-white' : ''
          }`}
        >
          {displayImage ? (
            <img
              className="lg:w-full sm:w-[50%] w-full h-[200px] rounded-[10px] object-cover"
              src={displayImage}
            />
          ) : (
            <div className="upload-button">
              <img className="upload mt-[16px]" src="/images/upload.png" />
              <p className="text-[#212529] font-[400] text-[16px] text-center">
                Click or drag file to this area to upload
              </p>
              <p className="text-[#6C757D] font-[400] text-[14px] sm:w-[280px] w-100% text-center mb-[16px]">
                Support for a single or bulk upload. Maximum file size 2MB.
              </p>
            </div>
          )}
        </label>

        <input
          type="file"
          accept={accept || ''}
          id={id}
          className="d-none"
          onChange={(event) => {
            const file = event.target.files?.[0];
            if (!file) {
              return;
            }

            setDisplayImage(URL.createObjectURL(file));
            handleChange(event, type);
          }}
        />
      </div>

      <ShouldRender if={error}>
        <span className="text-[12px] text-red-600 font-[400]">{error}</span>
      </ShouldRender>
    </div>
  );
};

export default ImageInput;
