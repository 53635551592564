import { useLazyQuery } from '@apollo/client';
import {
  CreativeRequestsByBrandBriefIdQuery,
  CreativeRequestsByBrandBriefIdQueryVariables,
  CreativeRequestsByDateQuery,
  CreativeRequestsByDateQueryVariables,
  ListCreativeRequestsQuery,
  ListCreativeRequestsQueryVariables,
} from 'API';
import { getQuery } from 'hooks/utils';
import {
  getCreativeRequests,
  getCreativeRequestsByBrandBriefId,
  getCreativeRequestsByDate,
} from './queries';

export const UseGetAdminCreativeRequests = () => {
  const [getRequests, { data, loading, error }] = useLazyQuery<
    ListCreativeRequestsQuery,
    ListCreativeRequestsQueryVariables
  >(getQuery(getCreativeRequests));

  return {
    getRequests,
    data: data?.listCreativeRequests?.items || [],
    loading,
    error,
  };
};

export const UseAdminGetCreativeRequestsByDate = () => {
  const [getRequests, { data, loading, error, fetchMore }] = useLazyQuery<
    CreativeRequestsByDateQuery,
    CreativeRequestsByDateQueryVariables
  >(getQuery(getCreativeRequestsByDate));

  return {
    getRequests,
    data: data?.creativeRequestsByDate?.items || [],
    loading,
    error,
    nextToken: data?.creativeRequestsByDate?.nextToken,
    fetchMore,
  };
};

export const UseGetCreativeRequestsByBrandBriefId = () => {
  const [getRequests, { data, error, loading }] = useLazyQuery<
    CreativeRequestsByBrandBriefIdQuery,
    CreativeRequestsByBrandBriefIdQueryVariables
  >(getQuery(getCreativeRequestsByBrandBriefId));

  return {
    getRequests,
    data: data?.creativeRequestsByBrandBriefId?.items || [],
    error,
    loading,
  };
};
