import { useMemo, useState } from 'react';

export const MAX_PAGINATION_PER_PAGE = 6;

interface PaginationProps<T> {
  items: T[];
  maxItemsPerPage?: number;
}

export const usePagination = <T extends object | string | number>({
  items,
  maxItemsPerPage = MAX_PAGINATION_PER_PAGE,
}: PaginationProps<T>) => {
  const [page, setPage] = useState(1);

  const slicedItems = useMemo(() => {
    return items.slice((page - 1) * maxItemsPerPage, page * maxItemsPerPage);
  }, [items, page, maxItemsPerPage]);

  const handlePageChange = (value: number) => {
    setPage(value);
  };

  return {
    handlePageChange,
    slicedItems,
    page,
  };
};
