import { USER_TYPES, UserProfile } from 'API';
import { IconLoader } from 'components';
import { UseGetCreativeEarnings, UseUpdateUserProfile } from 'hooks';
import useZodForm from 'hooks/useZodForm';
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Routes from 'components/ui/switch';
import { AdminRoutes } from 'utils';
import { schema, defaultValues } from "./schema";
import Input from 'components/ui/input';
import TextArea from 'components/ui/textArea';

interface IGeneralFormProps {
    user: UserProfile;
    setShowModal: Dispatch<SetStateAction<boolean>>;
}

const GeneralForm: FC<IGeneralFormProps> = ({ user, setShowModal }) => {
    const [tag, setTag] = useState('');
    const { updateProfile, loading } = UseUpdateUserProfile();
    const { getEarnings } = UseGetCreativeEarnings();
    const {
        register,
        control,
        handleSubmit,
        reset,
        formState: { errors, isValid },
    } = useZodForm({
        schema,
        defaultValues,
        mode: 'all',
    });
    const [selectedVerticals, setSelectedVerticals] = useState<Set<string>>(
        new Set()
    );

    useEffect(() => {
        if (!user) {
            return;
        }

        setSelectedVerticals(new Set(user?.hashtags as string | null));
        reset({
            ...(user as {
                name: string;
                email: string;
                tiktokHandler: string;
                instagramHandler: string;
                description: string;
                youtubeHandler: string;
                uniqueId?: string;
                id?: string | undefined;
            }),
        });

        if (user.userType === USER_TYPES.CREATIVE_USER) {
            getEarnings({ variables: { creatorId: user.id } });
        }
    }, [user, reset]);

    const onSubmit = handleSubmit(async ({ isBrand, ...data }) => {
        await updateProfile({
            variables: {
                input: {
                    ...data,
                    hashtags: [...selectedVerticals],
                    userType: isBrand ? USER_TYPES.BRAND_USER : USER_TYPES.CREATIVE_USER,
                },
            },
            errorPolicy: 'ignore',
        });
        setShowModal(true);
    });

    const selectVertical = (vertical, action) => {
        if (
            (selectedVerticals.size < 5 && action === 'add') ||
            action === 'delete'
        ) {
            const verticals = new Set(selectedVerticals);
            if (!verticals.has(vertical)) setTag('');
            verticals[action](vertical);
            setSelectedVerticals(verticals);
        }
    };

    return <form
        onSubmit={onSubmit}
        onKeyDown={(e) => {
            if (e.keyCode == 13) e.preventDefault();
        }}
    >
        <div className="grid xl:grid-cols-1 p-6 xl:gap-8">
            <div className="xl:col-span-1 col-span-3">
                <Routes
                    name="isBrand"
                    label="Mark as Brand"
                    className="mb-5"
                    control={control}
                />

                <Input
                    name="uniqueId"
                    label="Creator ID"
                    disabled={true}
                    register={register}
                    className="mb-5"
                    errors={errors}
                />

                <Input
                    name="country"
                    label="Country"
                    disabled={true}
                    register={register}
                    className="mb-5"
                    errors={errors}
                />

                <Input
                    name="id"
                    label="User ID"
                    disabled={true}
                    register={register}
                    className="mb-5"
                    errors={errors}
                />
                <Input
                    required
                    name="name"
                    label="Creator Name"
                    register={register}
                    className="mb-5"
                    errors={errors}
                />
                <Input
                    required
                    name="email"
                    type="email"
                    label="Creator Email"
                    register={register}
                    className="mb-5"
                    errors={errors}
                />
                <Input
                    name="tiktokHandler"
                    label="Tiktok handler"
                    register={register}
                    className="mb-5"
                    errors={errors}
                />
                <Input
                    label="Instagram handler"
                    name="instagramHandler"
                    register={register}
                    errors={errors}
                />
                <Input
                    label="Youtube handler"
                    name="youtubeHandler"
                    register={register}
                    errors={errors}
                />
                <div className="brand-dashboard__profile-group lg:grid grid-cols-12 gap-[40px] mb-[20px]">
                    <div className="brand-dashboard__profile-group col-span-4 lg:mb-0 mb-3">
                        <div className="profile-label">
                            Add up to 5 tags that best describe you and your content
                        </div>
                        <div className="relative">
                            <input
                                className="profile-input pr-[36px]"
                                onKeyDown={(e) => {
                                    if (e.keyCode == 13 && tag.trim()) {
                                        e.preventDefault();
                                        selectVertical(tag, 'add');
                                    }
                                }}
                                maxLength={15}
                                onChange={(e) => setTag(e.target.value)}
                                value={tag}
                                type="text"
                            />
                            <img
                                src="/images/remove.svg"
                                className="absolute top-[11px] right-[12px]"
                            />
                        </div>
                    </div>
                    <div
                        className={`relative brand-dashboard__profile-group col-span-4 mt-[26px] lg:w-[300px] w-[270px] lg:mb-[20px] lg:mx-0 m-auto ${selectedVerticals.size ? 'h-[75px]' : ''
                            }`}
                    >
                        <div className="flex flex-wrap lg:h-auto h-[125px] items-baseline w-[200px]">
                            {[...selectedVerticals].map((vertical, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={`border bg-[#202020] text-[#fff] ${vertical?.split(' ') && vertical.length > 9
                                            ? 'w-[138px] justify-center'
                                            : ''
                                            } rounded-[80px] m-1 cursor-pointer w-fit flex px-2 py-1 position-${index + 1
                                            }`}
                                        onClick={() => selectVertical(vertical, 'delete')}
                                    >
                                        <span>{vertical}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <TextArea
                    rows={9}
                    name="description"
                    label="Description"
                    register={register}
                    errors={errors}
                />
            </div>
        </div>
        <div className="grid xl:grid-cols-2 grid-cols-1"></div>
        <div className="xl:hidden w-full px-6 pb-6"></div>
        <div
            className="
  flex sm:flex-row w-full sm:justify-center
  font-sans text-base text-white font-bold flex-col-reverse gap-4 items-center px-6"
        >
            <Link to={AdminRoutes.Creators}>
                <button className="creator-button bg-[#F1EBDF]">CANCEL</button>
            </Link>
            <button
                type="submit"
                className="creator-button disabled:bg-[#a8a8a8]"
                disabled={!isValid}
            >
                {loading ? <IconLoader /> : 'Save'}
            </button>
        </div>
    </form>
}


export default GeneralForm