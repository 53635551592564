import { AccountBalanceOutlined } from '@mui/icons-material';
import { SVGProps } from 'react';

export const MenuIcon1 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    className="filled-icon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.9082 5.83374L12.2748 2.40874C10.9195 1.41932 9.08014 1.41932 7.72484 2.40874L3.09984 5.83374C2.20437 6.47885 1.67159 7.51344 1.6665 8.61707V14.8087C1.71652 16.8031 3.37204 18.3803 5.3665 18.3337H14.6332C16.6276 18.3803 18.2832 16.8031 18.3332 14.8087V8.60874C18.326 7.50988 17.7971 6.47981 16.9082 5.83374ZM17.0832 14.8087C17.0335 16.1129 15.9374 17.1306 14.6332 17.0837H5.3665C4.06398 17.126 2.97069 16.1108 2.9165 14.8087V8.61707C2.92095 7.90867 3.26498 7.24541 3.8415 6.83374L8.4665 3.41707C9.37992 2.75005 10.6198 2.75005 11.5332 3.41707L16.1582 6.84207C16.7342 7.24659 17.0789 7.90487 17.0832 8.60874V14.8087ZM6.24984 13.1254H13.7498C14.095 13.1254 14.3748 13.4052 14.3748 13.7504C14.3748 14.0956 14.095 14.3754 13.7498 14.3754H6.24984C5.90466 14.3754 5.62484 14.0956 5.62484 13.7504C5.62484 13.4052 5.90466 13.1254 6.24984 13.1254Z"
      fill="#fff"
    />
  </svg>
);

export const MenuIcon2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    className="filled-icon"
  >
    <path
      d="M6.31625 7.56684C7.00661 7.56684 7.56625 7.00719 7.56625 6.31684C7.56625 5.62648 7.00661 5.06684 6.31625 5.06684C5.62589 5.06684 5.06625 5.62648 5.06625 6.31684C5.06625 7.00719 5.62589 7.56684 6.31625 7.56684Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.29958 1.6835H9.23292L4.23292 2.04184C3.06838 2.14604 2.14545 3.06897 2.04125 4.2335L1.67458 9.2335C1.62854 9.69358 1.78699 10.1506 2.10792 10.4835L9.14125 17.5168C9.43832 17.803 9.8372 17.959 10.2496 17.9502C10.8805 17.9447 11.4821 17.683 11.9162 17.2252L17.2246 11.9252C18.0492 11.2416 18.1786 10.0253 17.5162 9.1835L10.4412 2.10017C10.1427 1.81634 9.74481 1.66078 9.33292 1.66684L9.29958 1.6835ZM9.33292 3.02517C9.38174 3.00982 9.4341 3.00982 9.48292 3.02517L16.5246 10.0668C16.5927 10.3792 16.4761 10.7029 16.2246 10.9002L10.8996 16.2252C10.7242 16.4155 10.4828 16.5317 10.2246 16.5502H10.0829L3.06625 9.47517C3.04384 9.41611 3.04384 9.35089 3.06625 9.29184L3.43292 4.29184C3.49834 3.83835 3.84814 3.47845 4.29958 3.40017L9.29958 3.04184L9.33292 3.02517Z"
      fill="white"
    />
  </svg>
);

export const MenuIcon3 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    className="filled-icon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.8834 8.525C17.892 8.46141 17.892 8.39693 17.8834 8.33333C17.8834 6.7225 16.5775 5.41667 14.9667 5.41667H11.25C10.7898 5.41667 10.4167 5.04357 10.4167 4.58333C10.4167 4.0308 10.1972 3.5009 9.80649 3.11019C9.41579 2.71949 8.88589 2.5 8.33335 2.5H4.79169C3.0658 2.5 1.66669 3.89911 1.66669 5.625V15.0167C1.68035 16.2739 2.70105 17.2872 3.95835 17.2917H14.5084C15.4532 17.2874 16.2993 16.7057 16.6417 15.825L18.3834 10.4083C18.504 9.73748 18.3209 9.04768 17.8834 8.525ZM2.91669 5.625C2.91669 4.58947 3.75615 3.75 4.79169 3.75H8.33335C8.79359 3.75 9.16669 4.1231 9.16669 4.58333C9.17125 5.73203 10.1013 6.6621 11.25 6.66667H15C15.6923 6.66657 16.3126 7.09446 16.5584 7.74167C16.4142 7.72912 16.2692 7.72912 16.125 7.74167H5.95835C4.99822 7.74191 4.14003 8.34065 3.80835 9.24167L2.91669 11.5583V5.625ZM14.5084 16.0417C14.9306 16.0411 15.31 15.7838 15.4667 15.3917L17.2 10.1C17.2316 9.81922 17.1439 9.53806 16.9584 9.325C16.7508 9.08244 16.4441 8.94751 16.125 8.95833H5.95835C5.52009 8.95857 5.12807 9.23099 4.97502 9.64167L2.91669 15.1C2.97208 15.634 3.42144 16.0403 3.95835 16.0417H14.5084Z"
      fill="white"
    />
  </svg>
);

export const MenuIcon4 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    className="filled-icon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.22809 3.33334H14.772C15.7157 3.33101 16.6216 3.70428 17.2897 4.37078C17.9579 5.03728 18.3334 5.94224 18.3334 6.88597V12.9386C18.3334 13.8823 17.9579 14.7873 17.2897 15.4538C16.6216 16.1203 15.7157 16.4936 14.772 16.4912H5.22809C4.28436 16.4936 3.37847 16.1203 2.71033 15.4538C2.04218 14.7873 1.66668 13.8823 1.66669 12.9386V6.88597C1.66668 5.94224 2.04218 5.03728 2.71033 4.37078C3.37847 3.70428 4.28436 3.33101 5.22809 3.33334ZM14.7719 15.1754C15.9919 15.1758 16.989 14.2021 17.0176 12.9825V6.88597C17.0127 5.64918 16.0087 4.64912 14.7719 4.64913H5.22809C3.99129 4.64912 2.98731 5.64918 2.98248 6.88597V12.9386C2.98731 14.1754 3.99129 15.1755 5.22809 15.1754H14.7719Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.42985 10.1579C6.42985 8.22007 8.00078 6.64914 9.93862 6.64914C11.8765 6.64914 13.4474 8.22007 13.4474 10.1579C13.4474 12.0958 11.8765 13.6667 9.93862 13.6667C8.00078 13.6667 6.42985 12.0958 6.42985 10.1579ZM7.74564 10.1579C7.74564 11.3691 8.72747 12.3509 9.93862 12.3509C11.1498 12.3509 12.1316 11.3691 12.1316 10.1579C12.1316 8.94676 11.1498 7.96493 9.93862 7.96493C8.72747 7.96493 7.74564 8.94676 7.74564 10.1579Z"
      fill="white"
    />
    <path
      d="M13.4474 6.17984C13.4474 5.94973 13.6339 5.76318 13.8641 5.76318H14.7851C15.0152 5.76318 15.2018 5.94973 15.2018 6.17985V7.10967C15.2018 7.33979 15.0152 7.52634 14.7851 7.52634H13.8641C13.6339 7.52634 13.4474 7.33979 13.4474 7.10967V6.17984Z"
      fill="white"
    />
  </svg>
);

export const AdminMenuPaidIcon = () => (
  <>
    <AccountBalanceOutlined sx={{ fontSize: 20 }} className="text-white" />
  </>
);

export const MenuIcon5 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    className="filled-icon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 4.16667H5.00002C3.15907 4.16667 1.66669 5.65905 1.66669 7.5V12.5C1.66669 14.341 3.15907 15.8333 5.00002 15.8333H15C16.841 15.8333 18.3334 14.341 18.3334 12.5V7.5C18.3334 5.65905 16.841 4.16667 15 4.16667ZM5.00002 5.41667H15C15.5526 5.41667 16.0825 5.63616 16.4732 6.02686C16.8639 6.41756 17.0834 6.94747 17.0834 7.5V11.0417H2.91669V7.5C2.91669 6.34941 3.84943 5.41667 5.00002 5.41667ZM5.00002 14.5833H15C15.5526 14.5833 16.0825 14.3638 16.4732 13.9731C16.8639 13.5824 17.0834 13.0525 17.0834 12.5V12.2917H2.91669V12.5C2.91669 13.6506 3.84943 14.5833 5.00002 14.5833Z"
      fill="white"
    />
  </svg>
);

export const MenuIcon6 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    fill="none"
  >
    <g id="user">
      <g id="Vector">
        <path
          d="M14.8284 9.82843C15.5786 9.07828 16 8.06087 16 7C16 5.93913 15.5786 4.92172 14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7C8 8.06087 8.42143 9.07828 9.17157 9.82843C9.92172 10.5786 10.9391 11 12 11C13.0609 11 14.0783 10.5786 14.8284 9.82843Z"
          stroke="#fff"
          // strokeOpacity="0.7"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.05025 16.0503C8.36301 14.7375 10.1435 14 12 14C13.8565 14 15.637 14.7375 16.9497 16.0503C18.2625 17.363 19 19.1435 19 21H5C5 19.1435 5.7375 17.363 7.05025 16.0503Z"
          stroke="#fff"
          // strokeOpacity="0.7"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
  </svg>
);

export const MenuIcon7 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    className="filled-icon"
  >
    <path
      d="M10.0834 17.3333C9.66669 17.3333 9.16669 17.1667 8.83335 16.8333C3.41669 12.0833 3.33335 12 3.33335 11.9167L3.25002 11.8333C2.25002 10.8333 1.66669 9.41667 1.66669 8V7.83333C1.75002 4.83333 4.16669 2.5 7.16669 2.5C8.08335 2.5 9.33335 3 10.0834 4C10.8334 3 12.1667 2.5 13.0834 2.5C16.0834 2.5 18.4167 4.83333 18.5834 7.83333V8C18.5834 9.5 18 10.8333 17 11.9167L16.9167 12C16.8334 12.0833 16.1667 12.6667 11.4167 16.9167C11 17.1667 10.5834 17.3333 10.0834 17.3333ZM4.58335 11.6667C4.91669 12 6.58335 13.1667 9.66669 15.8333C9.91669 16.0833 10.25 16.0833 10.5 15.8333C13.6667 13 15.5 11.4167 15.9167 11.0833L16 11C16.8334 10.1667 17.25 9.08333 17.25 8V7.83333C17.1667 5.5 15.3334 3.75 13 3.75C12.4167 3.75 11.25 4.16667 10.8334 5.08333C10.6667 5.41667 10.3334 5.58333 10 5.58333C9.66669 5.58333 9.33335 5.41667 9.16669 5.08333C8.75002 4.25 7.66669 3.75 7.00002 3.75C4.75002 3.75 2.83335 5.58333 2.75002 7.83333V8.08333C2.75002 9.16667 3.25002 10.25 4.00002 11L4.58335 11.6667Z"
      fill="white"
    />
  </svg>
);

export const MenuIcon8 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    className="filled-icon"
  >
    <path
      d="M13.1585 6.79167H9.06686C8.7539 6.79167 8.50019 6.53797 8.50019 6.22501C8.50019 5.91204 8.7539 5.65834 9.06686 5.65834H13.1585C13.4715 5.65834 13.7252 5.91204 13.7252 6.22501C13.7252 6.53797 13.4715 6.79167 13.1585 6.79167Z"
      fill="white"
    />
    <path
      d="M9.09186 10.55H12.0085C12.3215 10.55 12.5752 10.8037 12.5752 11.1167C12.5752 11.4296 12.3215 11.6833 12.0085 11.6833H9.09186C8.7789 11.6833 8.52519 11.4296 8.52519 11.1167C8.52519 10.8037 8.7789 10.55 9.09186 10.55Z"
      fill="white"
    />
    <path
      d="M14.3252 8.10001H9.06686C8.84645 8.07333 8.63038 8.17592 8.51174 8.36358C8.3931 8.55124 8.3931 8.79044 8.51174 8.9781C8.63038 9.16576 8.84645 9.26835 9.06686 9.24167H14.3252C14.6139 9.20672 14.8311 8.96168 14.8311 8.67084C14.8311 8.38 14.6139 8.13495 14.3252 8.10001Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99186 1.66667H13.3919C15.578 1.66667 17.3502 3.43888 17.3502 5.62501V11.725C17.3655 13.3831 16.3437 14.8742 14.7919 15.4583C14.6321 17.1779 13.1855 18.4907 11.4585 18.4833H7.29186C5.10573 18.4833 3.33352 16.7111 3.33352 14.525V7.67501C3.32128 5.97959 4.58364 4.54508 6.26686 4.34167C6.81292 2.74829 8.30752 1.67499 9.99186 1.66667ZM7.25852 17.2H11.4252C12.3567 17.2007 13.1745 16.5805 13.4252 15.6833H9.99186C7.80573 15.6833 6.03352 13.9111 6.03352 11.725V5.65834C5.15549 5.91954 4.55263 6.72561 4.55019 7.64167V14.4917C4.55019 15.9874 5.76275 17.2 7.25852 17.2ZM16.1002 11.725C16.1002 13.2208 14.8876 14.4333 13.3919 14.4333H9.99186C8.49798 14.4288 7.2881 13.2189 7.28352 11.725V5.62501C7.2881 4.13113 8.49798 2.92125 9.99186 2.91667H13.3919C14.8876 2.91667 16.1002 4.12923 16.1002 5.62501V11.725Z"
      fill="white"
    />
  </svg>
);

export const MenuIcon9 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    fill="none"
  >
    <g id="chat-alt">
      <path
        id="Vector"
        d="M8 10H8.01M12 10H12.01M16 10H16.01M9 16H5C4.46957 16 3.96086 15.7893 3.58579 15.4142C3.21071 15.0391 3 14.5304 3 14V6C3 5.46957 3.21071 4.96086 3.58579 4.58579C3.96086 4.21071 4.46957 4 5 4H19C19.5304 4 20.0391 4.21071 20.4142 4.58579C20.7893 4.96086 21 5.46957 21 6V14C21 14.5304 20.7893 15.0391 20.4142 15.4142C20.0391 15.7893 19.5304 16 19 16H14L9 21V16Z"
        stroke="#fff"
        // strokeOpacity="0.7"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export const ExitIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M2.68278 9.14699L7.53278 4.34725C7.7768 4.10647 8.1721 4.10647 8.41612 4.34725C8.53524 4.46192 8.60243 4.61927 8.60243 4.78359C8.60243 4.9479 8.53524 5.10525 8.41612 5.21993L4.63278 8.96587L13.9911 8.96587C14.3363 8.96587 14.6161 9.24232 14.6161 9.58333C14.6161 9.92435 14.3363 10.2008 13.9911 10.2008L4.63278 10.2008L8.41612 13.9467C8.53496 14.0614 8.60119 14.219 8.59945 14.3831C8.60023 14.547 8.53415 14.7043 8.41612 14.8194C8.1721 15.0602 7.7768 15.0602 7.53278 14.8194L2.68278 10.0197C2.43907 9.7786 2.43907 9.38806 2.68278 9.14699Z"
      fill="white"
    />
    <path
      d="M15.8995 13.6998L15.8994 5.46691C15.8994 5.12589 16.1793 4.84945 16.5244 4.84945C16.8696 4.84945 17.1494 5.12589 17.1494 5.46691L17.1495 13.6998C17.1495 14.0408 16.8696 14.3172 16.5245 14.3172C16.1793 14.3172 15.8995 14.0408 15.8995 13.6998Z"
      fill="white"
    />
  </svg>
);

export const brandBriefIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.3999 3.59998C8.08164 3.59998 7.77642 3.7264 7.55137 3.95145C7.32633 4.17649 7.1999 4.48172 7.1999 4.79998C7.1999 5.11824 7.32633 5.42346 7.55137 5.6485C7.77642 5.87355 8.08164 5.99998 8.3999 5.99998H15.5999C15.9182 5.99998 16.2234 5.87355 16.4484 5.6485C16.6735 5.42346 16.7999 5.11824 16.7999 4.79998C16.7999 4.48172 16.6735 4.17649 16.4484 3.95145C16.2234 3.7264 15.9182 3.59998 15.5999 3.59998H8.3999ZM4.7999 8.39998C4.7999 8.08172 4.92633 7.77649 5.15137 7.55145C5.37642 7.3264 5.68164 7.19998 5.9999 7.19998H17.9999C18.3182 7.19998 18.6234 7.3264 18.8484 7.55145C19.0735 7.77649 19.1999 8.08172 19.1999 8.39998C19.1999 8.71824 19.0735 9.02346 18.8484 9.2485C18.6234 9.47355 18.3182 9.59998 17.9999 9.59998H5.9999C5.68164 9.59998 5.37642 9.47355 5.15137 9.2485C4.92633 9.02346 4.7999 8.71824 4.7999 8.39998ZM2.3999 13.2C2.3999 12.5635 2.65276 11.953 3.10285 11.5029C3.55293 11.0528 4.16338 10.8 4.7999 10.8H19.1999C19.8364 10.8 20.4469 11.0528 20.897 11.5029C21.347 11.953 21.5999 12.5635 21.5999 13.2V18C21.5999 18.6365 21.347 19.2469 20.897 19.697C20.4469 20.1471 19.8364 20.4 19.1999 20.4H4.7999C4.16338 20.4 3.55293 20.1471 3.10285 19.697C2.65276 19.2469 2.3999 18.6365 2.3999 18V13.2Z"
      fill="white"
    />
  </svg>
);

export const brandIdentityIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.2483 11.1516C21.4733 11.3767 21.5996 11.6818 21.5996 12C21.5996 12.3182 21.4733 12.6234 21.2483 12.8484L12.8483 21.2484C12.6233 21.4734 12.3181 21.5998 11.9999 21.5998C11.6817 21.5998 11.3765 21.4734 11.1515 21.2484L2.7515 12.8484C2.63991 12.7371 2.55139 12.6049 2.49106 12.4593C2.43072 12.3137 2.39974 12.1576 2.3999 12V6.00002C2.3999 5.04524 2.77919 4.12957 3.45432 3.45444C4.12945 2.77931 5.04512 2.40002 5.9999 2.40002H11.9999C12.3071 2.40002 12.6143 2.51762 12.8483 2.75162L21.2483 11.1516ZM5.9999 7.20002C6.31816 7.20002 6.62339 7.0736 6.84843 6.84855C7.07347 6.62351 7.1999 6.31828 7.1999 6.00002C7.1999 5.68176 7.07347 5.37654 6.84843 5.1515C6.62339 4.92645 6.31816 4.80002 5.9999 4.80002C5.68164 4.80002 5.37642 4.92645 5.15138 5.1515C4.92633 5.37654 4.7999 5.68176 4.7999 6.00002C4.7999 6.31828 4.92633 6.62351 5.15138 6.84855C5.37642 7.0736 5.68164 7.20002 5.9999 7.20002Z"
      fill="white"
    />
  </svg>
);

export const creativeLinking = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    className="filled-icon"
  >
    <path
      d="M12.1789 11.6957C11.621 11.3482 10.923 11.3142 10.3339 11.6057L8.08388 9.35575C8.25049 8.97569 8.28739 8.55136 8.18888 8.14825C8.17212 8.09212 8.15208 8.03703 8.12888 7.98325L10.3339 5.77825C11.1595 6.18534 12.1587 5.92724 12.684 5.17124C13.2092 4.41523 13.1024 3.38875 12.4328 2.75706C11.7632 2.12536 10.7322 2.07849 10.0081 2.64682C9.28392 3.21515 9.08443 4.2277 9.53888 5.02825L7.46138 7.07575C6.75402 6.57296 5.79406 6.62129 5.14078 7.1926C4.4875 7.7639 4.31165 8.70885 4.71567 9.47692C5.11969 10.245 5.998 10.6354 6.83888 10.4207C7.01365 10.3694 7.18029 10.2937 7.33388 10.1957L9.58388 12.4457C9.12177 13.3492 9.46565 14.4563 10.3583 14.9389C11.2509 15.4216 12.3656 15.1031 12.8685 14.2217C13.3714 13.3404 13.0785 12.2187 12.2089 11.6957H12.1789Z"
      fill="white"
    />
  </svg>
);
export const menu5 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="svg-icon"
    // style="width: 1em; height: 1em;vertical-align: middle;fill: white;overflow: hidden;"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M723.43 508.6c-54.123 47.75-125.977 77.056-205.163 77.056-80.364 0-153.4-30.259-207.765-79.421C184.05 539.325 105.81 652.308 105.81 787.277v68.782c0 160.968 812.39 160.968 812.39 0v-68.782c-0.005-131.415-74.22-242.509-194.77-278.677z m-205.163 28.13c140.165 0 254.095-109.44 254.095-244.64S658.668 47.218 518.267 47.218c-139.93 0-253.855 109.675-253.855 244.874 0 135.204 113.925 244.639 253.855 244.639z m0 0" />
  </svg>
);

export const Creators = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    className="filled-icon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.1166 12.2833L18.2333 12.8583C18.3603 13.3495 18.2567 13.8719 17.9517 14.2774C17.6468 14.6829 17.1738 14.9274 16.6666 14.9417H13.4916C13.4923 16.2905 12.423 17.3967 11.0749 17.4417H4.16659C3.40371 17.429 2.68836 17.0687 2.2241 16.4632C1.75984 15.8577 1.59749 15.0734 1.78326 14.3333L1.95826 13.5C2.16175 12.352 3.15087 11.5097 4.31659 11.4917H10.9333C11.2286 11.1387 11.6648 10.9344 12.1249 10.9333H16.5333C17.3116 10.9582 17.969 11.5187 18.1166 12.2833ZM16.6499 13.6833C16.7516 13.6796 16.8463 13.6307 16.9083 13.55C17.0173 13.44 17.0721 13.2875 17.0583 13.1333L16.9416 12.5583C16.919 12.3721 16.77 12.2265 16.5833 12.2083H12.1749C12.079 12.209 11.9892 12.2554 11.9333 12.3333C11.6943 12.6085 11.3477 12.7666 10.9833 12.7667H4.36659C3.7961 12.7806 3.31808 13.2023 3.23326 13.7667L3.05826 14.6C2.97278 15.0104 3.07348 15.4376 3.33326 15.7667C3.53617 16.0226 3.84029 16.1777 4.16659 16.1917H11.0499C11.7206 16.1471 12.236 15.5802 12.2166 14.9083C12.2302 14.2278 12.7859 13.6832 13.4666 13.6833H16.6499Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9166 6.66667C10.9166 8.50762 9.42421 10 7.58326 10C5.74231 10 4.24992 8.50762 4.24992 6.66667V5.35833C4.24992 3.81194 5.50353 2.55833 7.04992 2.55833H8.14159C9.67822 2.57205 10.9167 3.82165 10.9166 5.35833V6.66667ZM7.04992 3.80833C6.19388 3.80833 5.49992 4.50229 5.49992 5.35833L5.52492 6.66667C5.65792 7.71598 6.55055 8.50262 7.60826 8.50262C8.66596 8.50262 9.5586 7.71598 9.69159 6.66667V5.35833C9.69159 4.50229 8.99763 3.80833 8.14159 3.80833H7.04992Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.2333 6.875C17.2333 8.25571 16.114 9.375 14.7333 9.375C13.3525 9.375 12.2333 8.25571 12.2333 6.875V5.91667C12.2331 4.76976 13.1532 3.83487 14.2999 3.81667H15.1333C16.2931 3.81667 17.2333 4.75687 17.2333 5.91667V6.875ZM14.3166 5.08333C13.8564 5.08333 13.4833 5.45643 13.4833 5.91667V6.875C13.4833 7.56536 14.0429 8.125 14.7333 8.125C15.4236 8.125 15.9833 7.56536 15.9833 6.875V5.91667C15.9833 5.45643 15.6102 5.08333 15.1499 5.08333H14.3166Z"
      fill="white"
    />
  </svg>
);

export const EditWithPencilIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.49167 10.725L6.1 7.88331C6.18857 7.48842 6.38805 7.12703 6.675 6.84165L12.2333 1.33331C12.797 0.759128 13.5623 0.4273 14.3667 0.408315C14.9733 0.399622 15.5584 0.633626 15.9917 1.05831C16.9385 2.17704 16.8163 3.84766 15.7167 4.81665L10.1583 10.375C9.87295 10.6619 9.51156 10.8614 9.11667 10.95L6.275 11.5583H6.11667C5.90893 11.5663 5.71051 11.4718 5.58577 11.3054C5.46104 11.1391 5.42587 10.9222 5.49167 10.725ZM7.55833 7.73331C7.44157 7.84647 7.36047 7.9913 7.325 8.14998L6.91667 10.0916L8.85833 9.67498C9.01701 9.63952 9.16185 9.55841 9.275 9.44165L14.8333 3.88331C15.4422 3.39583 15.5631 2.51705 15.1083 1.88331C14.9092 1.69307 14.6419 1.59096 14.3667 1.59998C13.8921 1.61904 13.4437 1.82231 13.1167 2.16665L7.55833 7.73331Z"
      fill="black"
    />
    <path
      d="M15.5917 7.10831C15.2484 7.1128 14.9712 7.39001 14.9667 7.73331V12.475C14.9778 13.1452 14.7194 13.7919 14.2493 14.2697C13.7793 14.7476 13.137 15.0167 12.4667 15.0166H4.525C3.13948 14.9895 2.02927 13.8608 2.025 12.475V4.56665C2.04775 3.17922 3.17906 2.06646 4.56667 2.06665H9.30834C9.65352 2.06665 9.93334 1.78683 9.93334 1.44165C9.93334 1.09647 9.65352 0.816648 9.30834 0.816648H4.525C2.44709 0.816523 0.756172 2.48886 0.733337 4.56665V12.475C0.733337 14.5691 2.43092 16.2666 4.525 16.2666H12.4333C14.5242 16.2621 16.2167 14.5658 16.2167 12.475V7.73331C16.2122 7.39001 15.935 7.1128 15.5917 7.10831Z"
      fill="black"
    />
  </svg>
);

export const OpenFolderIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.8833 6.525C16.892 6.46141 16.892 6.39693 16.8833 6.33333C16.8833 4.7225 15.5775 3.41667 13.9667 3.41667H10.25C9.78976 3.41667 9.41667 3.04357 9.41667 2.58333C9.41667 2.0308 9.19718 1.5009 8.80647 1.11019C8.41577 0.719493 7.88587 0.5 7.33333 0.5H3.79167C2.06578 0.5 0.666668 1.89911 0.666668 3.625V13.0167C0.680326 14.2739 1.70103 15.2872 2.95833 15.2917H13.5083C14.4532 15.2874 15.2993 14.7057 15.6417 13.825L17.3833 8.40833C17.504 7.73748 17.3208 7.04768 16.8833 6.525ZM1.91667 3.625C1.91667 2.58947 2.75613 1.75 3.79167 1.75H7.33333C7.79357 1.75 8.16667 2.1231 8.16667 2.58333C8.17124 3.73203 9.10131 4.6621 10.25 4.66667H14C14.6923 4.66657 15.3126 5.09446 15.5583 5.74167C15.4142 5.72912 15.2692 5.72912 15.125 5.74167H4.95833C3.99821 5.74191 3.14002 6.34065 2.80833 7.24167L1.91667 9.55833V3.625ZM13.5083 14.0417C13.9306 14.0411 14.31 13.7838 14.4667 13.3917L16.2 8.1C16.2316 7.81922 16.1439 7.53806 15.9583 7.325C15.7507 7.08244 15.4441 6.94751 15.125 6.95833H4.95833C4.52007 6.95857 4.12805 7.23099 3.975 7.64167L1.91667 13.1C1.97206 13.634 2.42142 14.0403 2.95833 14.0417H13.5083Z"
      fill="#1D1C1C"
    />
  </svg>
);

export const PlayArrowIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.09639 0.447395L13.3072 5.11142C14.3551 5.70884 15 6.80939 15 8C15 9.19061 14.3551 10.2912 13.3072 10.8886L5.09639 15.5526C4.04483 16.1493 2.74921 16.1491 1.69785 15.5521C0.646492 14.955 -0.000776683 13.8519 6.99452e-07 12.6585V3.34154C-0.000776683 2.14811 0.646492 1.04498 1.69785 0.44793C2.74921 -0.149119 4.04483 -0.149323 5.09639 0.447395ZM4.07711 13.8161L12.288 9.16323C12.7104 8.92616 12.9701 8.48371 12.9675 8.00557C12.9719 7.52407 12.7122 7.07752 12.288 6.83678L4.07711 2.18388C3.87175 2.06256 3.63719 1.99723 3.39759 1.99465C2.64076 1.99465 2.02723 2.59767 2.02723 3.34154V12.6585C2.02723 13.0157 2.17161 13.3583 2.4286 13.6109C2.68559 13.8634 3.03415 14.0054 3.39759 14.0054C3.63719 14.0028 3.87175 13.9374 4.07711 13.8161Z"
      fill="#1D1C1C"
    />
  </svg>
);

export const RedHeartIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 18.1459L8.79167 17.0459C4.5 13.1542 1.66667 10.5876 1.66667 7.43758C1.66667 4.87091 3.68333 2.85425 6.25 2.85425C7.7 2.85425 9.09167 3.52925 10 4.59591C10.9083 3.52925 12.3 2.85425 13.75 2.85425C16.3167 2.85425 18.3333 4.87091 18.3333 7.43758C18.3333 10.5876 15.5 13.1542 11.2083 17.0542L10 18.1459Z"
      fill="#FA0808"
    />
  </svg>
);

export const BlueCloseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 2.16675C5.39167 2.16675 1.66667 5.89175 1.66667 10.5001C1.66667 15.1084 5.39167 18.8334 10 18.8334C14.6083 18.8334 18.3333 15.1084 18.3333 10.5001C18.3333 5.89175 14.6083 2.16675 10 2.16675ZM14.1667 13.4917L12.9917 14.6667L10 11.6751L7.00833 14.6667L5.83333 13.4917L8.825 10.5001L5.83333 7.50841L7.00833 6.33342L10 9.32508L12.9917 6.33342L14.1667 7.50841L11.175 10.5001L14.1667 13.4917Z"
      fill="#2196F3"
    />
  </svg>
);

export const IIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="7"
    height="11"
    viewBox="0 0 7 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.52083 10.0313H4.2375V3.61875C4.22583 3.50539 4.15417 3.39878 4.03583 3.31875C3.91108 3.22301 3.72819 3.17221 3.54083 3.18125H1.7075C1.526 3.18124 1.35205 3.23082 1.22458 3.31892C1.0971 3.40701 1.02671 3.52626 1.02917 3.65C1.04328 3.90479 1.34297 4.10913 1.71667 4.11875H2.8625V10.0625H0.9375C0.557804 10.0625 0.25 10.2724 0.25 10.5313C0.25 10.7901 0.557804 11 0.9375 11H5.52083C5.90053 11 6.20833 10.7901 6.20833 10.5313C6.20833 10.2724 5.90053 10.0625 5.52083 10.0625V10.0313Z"
      fill="black"
    />
    <path
      d="M4.60417 1.4375C4.60417 1.95527 3.98856 2.375 3.22917 2.375C2.46977 2.375 1.85417 1.95527 1.85417 1.4375C1.85417 0.919733 2.46977 0.5 3.22917 0.5C3.98856 0.5 4.60417 0.919733 4.60417 1.4375Z"
      fill="black"
    />
  </svg>
);
