import { MenuItem } from '@mui/material';
import { CAMPAIGNS_RESPONSE, IDENTITIES_RESPONSE } from 'API';
import { CALL_TO_ACTION_OPTIONS } from 'hooks/utils';
import { FC, useMemo } from 'react';

export interface ITiktokCampaignOptions {
  campaigns?: Array<CAMPAIGNS_RESPONSE | null> | null;
}

export const TiktokCampaignOptions: FC<ITiktokCampaignOptions> = ({
  campaigns,
}) =>
  (campaigns || []).map((c, idx) => (
    <MenuItem value={c?.campaign_id || ''} key={idx}>
      {c?.campaign_name}
    </MenuItem>
  ))

export interface ITiktokIdentitiesOptionsProps {
  identities?: Array<IDENTITIES_RESPONSE | null> | null;
}
export const TiktokIdentitiesOptions = ({
  identities
}: ITiktokIdentitiesOptionsProps) => {
  return (identities || [])?.map((identity, idx) => (
    <MenuItem key={idx} value={identity?.identity_id || ''}>
      <div className="flex gap-3 items-middle">
        <img
          className="rounded-full w-6 h-auto"
          src={identity?.profile_image || '/images/default-image.png'}
        />

        <span className="text-black font-normal font-roboto">
          {identity?.display_name || identity?.identity_id || ''}
        </span>
      </div>
    </MenuItem>
  ))
}
  ;

export interface ITiktokAdGroupOptionsProps {
  adGroups?: string | null;
}
export const TiktokAdgroupOptions: FC<ITiktokAdGroupOptionsProps> = ({
  adGroups,
}) =>
  useMemo(() => {
    if (!adGroups) {
      return [];
    }

    try {
      const { data } = JSON.parse(adGroups);
      if (!data.list?.length) {
        return [];
      }

      return data.list.map((adgroup, idx) => (
        <MenuItem key={idx} value={adgroup.adgroup_id}>
          {adgroup.adgroup_name}
        </MenuItem>
      ));
    } catch (error) {
      return [];
    }
  }, [adGroups]);

export const CallToActionOptions = () =>
  useMemo(
    () =>
      CALL_TO_ACTION_OPTIONS.map(({ text, value }) => (
        <MenuItem value={value} key={text}>
          {text}
        </MenuItem>
      )),
    []
  );
