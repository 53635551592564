import { UserProfile } from 'API';
import { Storage } from 'aws-amplify';
import { VideoCarousel } from 'components/VideoCarousel';
import { getUserProfile } from 'hooks';
import {
  MOBILE_DEVICE_ASPECT_RATIO,
  PORTFOLIO_VIDEO_SIZE_LIMIT_IN_BYTES,
} from 'hooks/utils';
import { FC, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { withProfile } from 'state/profileSteps';
import { ProfileProps } from 'utils';
import { areFloatsSimilar, getVideoDimensionsOf } from 'utils/utils';
import { v4 as uuid } from 'uuid';

const MOBILE_DEVICE_WIDTH = 1281;

interface IProfileDatProps {
  user: UserProfile;
}

const ProfileData: FC<IProfileDatProps & ProfileProps> = ({
  updateProfileLoading,
  profileState: { data },
  editCreatorProfile,
  setProfileState,
  user,
}) => {
  const [upLoading, setUpLoading] = useState(false);
  const [videoPreviewUrls, setVideoPreviewUrls] = useState<string[]>([]);
  const [fileCount, setFileCount] = useState(0);
  const [totalFiles, setTotalFiles] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [contentError, setContentError] = useState('');

  const { getProfile, profileData, loading } = getUserProfile();

  useEffect(() => {
    getProfile({ variables: { id: user.id } });
  }, [profileData]);

  const getContentVideos = useCallback(async () => {
    const content = profileData?.profileContent || [];
    console.log({ content });
    const previewUrls: string[] = await Promise.all(
      content?.map((c) => Storage.get((c || '').replace('public/', '')))
    );
    setVideoPreviewUrls(previewUrls);
  }, [profileData?.profileContent]);

  useEffect(() => {
    getContentVideos();
  }, [profileData?.profileContent]);

  const onReset = () => {
    setUpLoading(false);
    setFileCount(0);
  };

  const getVideoKeyFromSignedUrl = (signedUrl: string): string =>
    signedUrl.split('public/')?.pop()?.split('?')?.shift() || '';

  const updateProgress = (progress: { loaded: number; total: number }) => {
    setPercentage(Math.floor((progress.loaded / progress.total) * 100));
  };

  const validatePortfolioContentSize = (size) =>
    size < PORTFOLIO_VIDEO_SIZE_LIMIT_IN_BYTES;

  const validateVideoSizeRatio = async (file: File) => {
    const { width, height } = await getVideoDimensionsOf(file);
    return (
      width &&
      height &&
      areFloatsSimilar(MOBILE_DEVICE_ASPECT_RATIO, width / height)
    );
  };

  const putObject = async (
    key: string,
    file: File,
    progressCallback: (progress: { loaded: number; total: number }) => void
  ) => {
    await Storage.put(key, file, {
      progressCallback,
      level: 'public',
      acl: 'public-read',
    });

    return Storage.get(key);
  };

  const validateVideoSizeAndRatio = async (file: File) => {
    const isValidRatio = await validateVideoSizeRatio(file);
    const isValidSize = validatePortfolioContentSize(file.size);
    return isValidRatio && isValidSize;
  };

  const validationContent = (previewVideos, newVideos) => {
    if (previewVideos?.length + newVideos?.length > 6) {
      setContentError('maximum videos count is 6');
      setTimeout(() => {
        setContentError('');
      }, 3000);
      return false;
    }
    return true;
  };

  const updateProfileStateContent = useCallback(
    (profileContent: string[]) => {
      if (profileData == null) {
        return;
      }

      setProfileState((prev) => ({
        ...prev,
        data: { ...profileData, profileContent },
      }));
    },
    [profileData, setProfileState]
  );

  const onDeleteVideo = useCallback(
    async (url: string) => {
      const key = url.split('public/').pop()?.split('?').shift();
      if (!videoPreviewUrls?.length || !key) {
        return;
      }

      const content = data?.profileContent || [];
      const previewUrls = videoPreviewUrls || [];

      const updatedProfileContent = content.filter((c) => !c.endsWith(key));
      const updatedPreviewUrls = previewUrls?.filter((u) => !u.includes(url));

      await editCreatorProfile({
        profileContent: updatedProfileContent,
      });

      updateProfileStateContent(updatedProfileContent);
      setVideoPreviewUrls(updatedPreviewUrls);
    },
    [videoPreviewUrls]
  );

  const onChooseVideos = useCallback(
    async (files) => {
      const previewUrls: string[] = [];
      const uploadedContent: string[] = [];
      const isValidVideo = validationContent(videoPreviewUrls, files);
      if (!files?.length) {
        return;
      }

      if (!isValidVideo) {
        toast.error('Please select a valid video');
      }

      setUpLoading(true);
      setTotalFiles(files?.length);

      for (const file of files) {
        if (!file) {
          continue;
        }

        const isValidVideo = await validateVideoSizeAndRatio(file);
        if (!isValidVideo) {
          onReset();
          toast.error(
            'Video should have size ratio of 16/9 and size under 30mb'
          );
          return;
        }

        const userId = data?.id;
        const fileSuffix = process.env.NODE_ENV === 'development' ? '.dev' : '';

        const key = `content/${userId}/${uuid()}${fileSuffix}`;
        const url = await putObject(key, file, updateProgress);

        setPercentage(0);
        setFileCount((prev) => ++prev);
        uploadedContent.push(key);
        previewUrls.push(url);
      }

      setVideoPreviewUrls((prev) => [
        ...new Set([...(prev || []), ...previewUrls]),
      ]);
      const updatedProfileContent = [
        ...new Set([
          ...(videoPreviewUrls || []).map(getVideoKeyFromSignedUrl),
          ...uploadedContent,
        ]),
      ];
      await editCreatorProfile({
        profileContent: updatedProfileContent,
      });
      updateProfileStateContent(updatedProfileContent);
      onReset();
    },
    [
      profileData?.id,
      editCreatorProfile,
      updateProfileStateContent,
      videoPreviewUrls,
    ]
  );

  console.log({ profileData });

  return (
    <div>
      {!loading && (
        <VideoCarousel
          isLoading={upLoading}
          isProfileLoading={updateProfileLoading}
          videoUrls={videoPreviewUrls}
          onDelete={onDeleteVideo}
          onChoose={onChooseVideos}
          isAutoPlay={window.innerWidth < MOBILE_DEVICE_WIDTH}
          fileCount={fileCount}
          totalFiles={totalFiles}
          percentage={percentage}
          error={contentError}
          profileVideoClasses="flex justify-center flex-wrap gap-[10px]"
          mainText={profileData?.name + ' content portfolio'}
        />
      )}
    </div>
  );
};

export default withProfile(ProfileData);
