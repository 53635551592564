import { GridColDef } from '@mui/x-data-grid';

export const TABLE_HEAD: GridColDef[] = [
  {
    field: 'img',
    headerName: 'Brand',
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    filterable: false,
    maxWidth: 120,
    editable: false,
    renderCell: ({ row }) => (
      <div className="flex justify-center justify-items-center mt-3">
        <img
          className="rounded-full w-[40px] h-[40px]"
          src={
            row?.brandProfile?.userProfile?.avatar || 'images/default-image.png'
          }
        />
      </div>
    ),
  },
  {
    field: 'activationName',
    headerName: 'Activation Name',
    sortable: true,
    editable: false,
    flex: 1,
    renderCell: ({ row }) => row.BriefName || 'N/A',
    valueGetter: (value, row) => row.BriefName,
  },
  {
    field: 'objective',
    filterable: false,
    flex: 1,
  },
  {
    field: 'country',
    filterable: false,
    flex: 1,
  },
  {
    field: 'status',
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    filterable: false,
    flex: 1,
  },
  {
    field: 'details',
    align: 'center',
    headerAlign: 'center',
    headerName: 'Details',
    sortable: false,
    filterable: false,
    flex: 1,
  },
  {
    field: 'edit',
    headerName: 'Edit',
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    filterable: false,
    flex: 1,
  },
  {
    field: 'isCreationNotificationSent',
    headerName: 'Notification',
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    filterable: false,
    maxWidth: 120,
  },
];
