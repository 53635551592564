import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import { TextInput } from 'components/TextInput';
import useZodForm from 'hooks/useZodForm';
import { FC } from 'react';
import { z } from 'zod';

interface IPreviewWindowModalProps {
    title: string;
    isModalOpen: boolean;
    isApprove: boolean;
    onClose: () => void;
    onSubmit: (data) => Promise<void>;
    isLoading: boolean;
}

const PreviewWindowModal: FC<IPreviewWindowModalProps> = ({
    onClose,
    onSubmit,
    isModalOpen,
    title,
    isApprove,
    isLoading,
}) => {
    const modalSchema = z.object({
        reason: isApprove ? z.string().optional() : z.string().min(10),
    });

    const { control, handleSubmit } = useZodForm({
        schema: modalSchema,
    });
    const inputLabel = !isApprove ? 'Rejection reason' : 'Approval comments';
    const modalTitle = isApprove
        ? `APPROVING - ${title}`
        : `REJECTING - ${title}`;

    return (
        <Dialog
            onClose={onClose}
            open={isModalOpen}
            fullWidth={true}
            sx={{ mr: 'auto', p: 6 }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle
                    sx={{
                        fontWeight: 600,
                        fontFamily: 'oswald',
                        fontSize: '22px',
                        textTransform: 'uppercase',
                    }}
                >
                    {modalTitle}
                </DialogTitle>

                <DialogContent>
                    {!isLoading ? (
                        <Box pt={0.5}>
                            <TextInput
                                required={!isApprove}
                                rows={3}
                                name="reason"
                                label={inputLabel}
                                control={control}
                                multiline={true}
                            />
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                p: 2,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    )}
                </DialogContent>

                <DialogActions sx={{ gap: 2, pb: 2, pr: 2 }}>
                    <Button
                        disableRipple={true}
                        onClick={onClose}
                        className="text-main-light-blue font-semibold"
                    >
                        Cancel
                    </Button>

                    <Button
                        disableRipple={true}
                        type="submit"
                        className="text-main-light-blue font-semibold"
                    >
                        Submit
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default PreviewWindowModal;
