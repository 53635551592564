import { InMemoryCache } from '@apollo/client';
import { CachePersistor, LocalStorageWrapper } from 'apollo3-cache-persist';

const LOCAL_STORAGE_KEYS = {
  GRAPHQL_SCHEMA_VERSION: 'graphqlSchemaVersion',
};

// Define the cache with custom merge functions
const cache = new InMemoryCache({
  typePolicies: {
    UserPaymentDetails: {
      fields: {
        userTransactions: {
          keyArgs: false, // Ignore arguments, enabling full merge based on custom merge logic
          merge(existing = { items: [] }, incoming) {
            // Custom merge logic: Merge existing and incoming transactions
            const existingItems = Array.isArray(existing.items) ? existing.items : [];
            const incomingItems = Array.isArray(incoming.items) ? incoming.items : [];
            return {
              ...incoming, // Keep other fields (like nextToken, if applicable) from incoming data
              items: [...existingItems, ...incomingItems], // Combine existing items with incoming items
            };
          },
        },
      },
    },
  },
});

// This is a MD5 hash of schema.graphql
const SCHEMA_VERSION = process.env.REACT_APP_GRAPHQL_SCHEMA_VERSION;

export const setupApolloCache = async (): Promise<InMemoryCache> => {
  const persistor = new CachePersistor({
    cache,
    storage: new LocalStorageWrapper(window.localStorage),
    maxSize: 524288, // 0.5 MB
  });

  const currentSchemaVersion = localStorage.getItem(
    LOCAL_STORAGE_KEYS.GRAPHQL_SCHEMA_VERSION
  );

  if (currentSchemaVersion === SCHEMA_VERSION) {
    // If the current version matches the latest version,
    // we're good to go and can restore the cache.
    await persistor.restore();
  } else {
    // Otherwise, we'll want to purge the outdated persisted cache
    await persistor.purge();

    if (SCHEMA_VERSION) {
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.GRAPHQL_SCHEMA_VERSION,
        SCHEMA_VERSION
      );
    }
  }

  return cache;
};