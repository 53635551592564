import { CircularProgress } from '@mui/material';
import { BrandBrief, WhitelistEntry } from 'API';
import { StyledTextField } from 'components/TextField';
import { UseUpdateBrandBriefWhitelist } from 'hooks';
import { UpdateBrandBrief } from 'hooks/query/useAdminActions';
import { ENTER_KEY_CODE } from 'hooks/utils';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { isEmail } from 'utils/utils';
import AccessSettings from './AccessSettings/AccessSettings';
import { ErrorMessages, SuccessMessages } from './constants';
import { EmailDisplay } from './EmailDisplay/EmailDisplay';
import RemoveModal from './RemoveModal/RemoveModal';

interface IProps {
  brandBrief?: BrandBrief | null;
  getBrandBriefs?: () => Promise<void>;
}

export const AdminBriefsAccess = ({ brandBrief, getBrandBriefs }: IProps) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [isSettingsDialogOpen, setIsSettingsDialogOpen] = useState(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [selectedWhitelistEntry, setSelectedWhitelistEntry] =
    useState<WhitelistEntry | null>(null);
  const [entries, setEntries] = useState<
    Array<Omit<WhitelistEntry, '__typename'>>
  >(brandBrief?.whitelist || []);

  const { updateWhitelist, loading: updateLoading } =
    UseUpdateBrandBriefWhitelist();

  const { updateBrief } = UpdateBrandBrief();

  const onReset = () => {
    setIsRemoveModalOpen(false);
    setSelectedWhitelistEntry(null);
    setIsSettingsDialogOpen(false);
  };

  const onRemoveEntry = useCallback(async () => {
    if (!brandBrief?.id || !selectedWhitelistEntry) {
      toast.error(ErrorMessages.InvalidEntry);
      return;
    }

    const updatedEntries = entries
      .filter(({ email }) => email !== selectedWhitelistEntry?.email)
      // @ts-expect-error
      .map(({ __typename, ...e }) => e);

    await updateBrief({
      variables: {
        input: {
          id: brandBrief.id,
          whitelist: updatedEntries,
        },
      },
      errorPolicy: 'ignore',
      ignoreResults: true,
    });

    setEntries(updatedEntries);
    onReset();
    toast.success(SuccessMessages.Removed);
  }, [brandBrief?.id, selectedWhitelistEntry, entries, updateBrief]);

  const onKeyDown = (event) => {
    const value = event.target.value?.trim();
    if (event.keyCode !== ENTER_KEY_CODE || !value) {
      return;
    }

    if (!isEmail(value)) {
      toast.error(ErrorMessages.InvalidEntry);
      return;
    }

    setInputValue('');
    setEntries((prev) =>
      prev
        .filter(({ email }) => email !== value)
        .concat([{ email: value.toLowerCase(), isApprover: false }])
    );
  };

  const onOpenRemoveModal = (entry: WhitelistEntry) => {
    setIsRemoveModalOpen(true);
    setSelectedWhitelistEntry(entry);
  };

  const onCancelRemoveEntry = () => {
    setIsRemoveModalOpen(false);
    setSelectedWhitelistEntry(null);
  };

  const onSubmit = useCallback(async () => {
    if (!entries.length || !brandBrief?.id) {
      return;
    }

    await updateWhitelist({
      variables: {
        brandBriefId: brandBrief.id,
        entries: entries.map((e) => ({
          email: e.email,
          isApprover: e.isApprover,
        })),
      },
    });

    onReset();
    toast.success(SuccessMessages.Updated);
  }, [entries, brandBrief]);

  const onSettings = (entry: { email: string; isApprover: boolean }) => {
    setSelectedWhitelistEntry(entry as WhitelistEntry);
    setIsSettingsDialogOpen(true);
  };

  useEffect(() => {
    if (!updateLoading || !getBrandBriefs) {
      return;
    }

    getBrandBriefs();
  }, [updateLoading]);

  const onAccessSettingsChange = useCallback(() => {
    if (!selectedWhitelistEntry) {
      return;
    }

    const updatedEntry = {
      email: selectedWhitelistEntry.email,
      isApprover: !selectedWhitelistEntry.isApprover,
    };

    setSelectedWhitelistEntry(updatedEntry as WhitelistEntry);
    setEntries((prev) =>
      prev
        .filter((entry) => entry.email !== selectedWhitelistEntry.email)
        .concat([updatedEntry])
    );
  }, [selectedWhitelistEntry]);

  const onCloseAccessSettings = () => {
    setIsSettingsDialogOpen(false);
  };

  return (
    <div className="w-full">
      <div className="flex flex-col gap-y-8 w-full">
        <div className="w-full max-w-[600px]">
          <StyledTextField
            name="ids"
            label="Enter email address"
            type="text"
            fullWidth={true}
            value={inputValue}
            onKeyDown={(event) => onKeyDown(event)}
            onChange={(e) => setInputValue(e.target.value)}
            InputProps={{ className: 'border-black' }}
          />
        </div>

        <div className="max-w-[600px] flex flex-col gap-y-6">
          <div className="flex flex-row gap-4 flex-wrap w-full max-w-[600px]">
            {entries.map((entry) => (
              <EmailDisplay
                key={entry.email}
                entry={entry as WhitelistEntry}
                onDelete={onOpenRemoveModal}
                onSettings={() => onSettings(entry)}
              />
            ))}
          </div>

          <div className="flex justify-end">
            <button
              className="bg-main-black text-white font-bold py-2 px-4 min-w-[70px] min-h-[40px] rounded-[14px] items-end disabled:bg-neutral-400 text-center align-center"
              disabled={!entries.length}
              onClick={onSubmit}
            >
              <div className="flex justify-center items-center">
                {updateLoading ? (
                  <CircularProgress className="w-4 h-4 text-white" />
                ) : (
                  'Save'
                )}
              </div>
            </button>
          </div>
        </div>
      </div>

      {selectedWhitelistEntry && (
        <RemoveModal
          isModalOpen={isRemoveModalOpen}
          entry={selectedWhitelistEntry}
          onAccept={onRemoveEntry}
          onClose={onCancelRemoveEntry}
        />
      )}

      {isSettingsDialogOpen && selectedWhitelistEntry && (
        <AccessSettings
          loading={updateLoading}
          onSubmit={onSubmit}
          entry={selectedWhitelistEntry}
          onCancel={onCloseAccessSettings}
          onAccessSettingsChange={onAccessSettingsChange}
        />
      )}
    </div>
  );
};
