import { OpenInNew, Search } from '@mui/icons-material';
import { debounce, IconButton, InputAdornment } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid';
import { BrandBrief, CreativeRequest, CREATIVE_REQUEST_RESPONSE } from 'API';
import {
  renderCustomDataGridTableHeaderCell,
  StyledDataGrid,
} from 'components/DataGrid';
import { StyledTextField } from 'components/TextField';
import { GetCreativeRequests } from 'hooks';
import {
  DEFAULT_DEBOUNCE_TIME_IN_MILLISECONDS,
  DEFAULT_PAGE_SIZE,
} from 'hooks/utils';
import CreativeDetails from 'pages/creativeDetails/creativeDetails';
import { FC, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BrandBriefProps,
  GetCreativeRequestsByBrandIdPayload,
  withBrandBriefs,
} from 'state/brandBrief';
import { BrandRoutes } from 'utils';
import { TABLE_HEADER_CELLS } from './constants';

export type RequestWithBrief = CreativeRequest & {
  brief?: BrandBrief;
};

export const BrandCreativeRequests: FC<BrandBriefProps> = ({
  getBrandBriefs,
  brand,
  data: briefs,
  profileState,
}) => {
  const navigate = useNavigate();
  const [selectedRequest, setSelectedRequest] =
    useState<CREATIVE_REQUEST_RESPONSE | null>();
  const [requestsTableData, setRequestsTableData] = useState<
    Partial<CreativeRequest>[]
  >([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [search, setSearch] = useState('');
  const [rowsCount, setRowsCount] = useState(0);
  const { listCreativeRequests, data, loading } = GetCreativeRequests();

  const getCreativeRequests = ({
    page,
    pageSize,
    search,
  }: GetCreativeRequestsByBrandIdPayload) => {
    const brandId = profileState?.brand?.items?.[0]?.id;
    if (!brandId) {
      return;
    }

    listCreativeRequests({ variables: { brandId, page, pageSize, search } });
  };

  useLayoutEffect(() => {
    getCreativeRequests({ page: 0, pageSize: 10 });
  }, []);

  const checkParam = () => {
    const url = new URL(window.location.href);
    return !!url.searchParams.get('creative');
  };

  const onDetails = (request) => {
    request && setSelectedRequest(request);
    getBrandBriefs({ page: 1, pageSize: 1000 });
    navigate(`${BrandRoutes.Creatives}?creative=true`);
  };

  const tableHead = useMemo(() => {
    const cells = TABLE_HEADER_CELLS.slice().map((c) =>
      c.field === 'details'
        ? {
            ...c,
            renderCell: (params) => (
              <div className="h-full flex justify-center">
                <IconButton
                  onClick={() => {
                    onDetails(params.row);
                  }}
                >
                  <OpenInNew />
                </IconButton>
              </div>
            ),
          }
        : c
    );

    return renderCustomDataGridTableHeaderCell(cells, 'font-bold');
  }, []);

  useEffect(() => {
    if (!data) {
      return;
    }

    const tableData = data?.items?.map((cr) => ({
      ...cr,
      id: cr?.id || '',
      brandBriefId: cr.brandBriefId,
      img: cr?.creatorProfileImage || '/images/default-image.png',
      uniqueId: cr?.uniqueId,
      creatorHandle: cr?.creativeTiktokHandle,
      createdAt: new Date(cr?.createdAt || '').toLocaleString().split(',')[0],
      brandName: brand?.name,
      creatorName: cr?.creatorName,
      briefName: cr?.BriefName,
      status: cr?.status,
    })) as unknown as CreativeRequest[];

    setRequestsTableData(tableData || []);
    setRowsCount(data?.totalItems || 0);
  }, [data]);

  useEffect(() => {
    if (!selectedRequest) {
      navigate(BrandRoutes.Creatives);
    }
  }, []);

  const onPageChange = (model) => {
    const payload =
      model.pageSize !== pageSize
        ? { page: 0, pageSize: model.pageSize }
        : { page: model.page, pageSize };

    setPage(payload.page);
    setPageSize(payload.pageSize);
    getCreativeRequests({ ...payload, ...(search && { search }) });
  };

  const onSearch = debounce((search) => {
    getCreativeRequests({ page: 0, pageSize: DEFAULT_PAGE_SIZE, search });
    setPage(0);
  }, DEFAULT_DEBOUNCE_TIME_IN_MILLISECONDS);

  if (selectedRequest && checkParam())
    return (
      <CreativeDetails
        creativeRequest={selectedRequest}
        requests={data?.items || []}
        onBack={() => setSelectedRequest(null)}
        briefs={(briefs || []) as BrandBrief[]}
      />
    );

  return (
    <div className="grid grid-cols-1">
      <section className="flex flex-col gap-y-4">
        <div className="w-[220px] mt-6">
          <StyledTextField
            variant="standard"
            placeholder="Search..."
            fullWidth={true}
            onChange={(event) => onSearch(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </div>

        <StyledDataGrid
          sx={{ minHeight: 600 }}
          density="comfortable"
          rows={requestsTableData || []}
          columns={tableHead}
          initialState={{
            pagination: {
              paginationModel: { page, pageSize },
            },
          }}
          onRowClick={(params: GridRowParams) => onDetails(params.row)}
          rowCount={rowsCount}
          onPaginationModelChange={onPageChange}
          pageSizeOptions={[5, 10]}
          paginationMode="server"
          disableColumnResize={true}
          disableRowSelectionOnClick={true}
          disableAutosize={true}
          disableColumnMenu={true}
          autosizeOnMount={true}
          disableColumnSelector={true}
          pagination={true}
          loading={loading}
          autosizeOptions={{
            expand: true,
            includeHeaders: true,
            includeOutliers: true,
          }}
        />
      </section>
    </div>
  );
};
export default withBrandBriefs(BrandCreativeRequests);
