import { CognitoUser } from '@aws-amplify/auth';
import { AuthClass } from '@aws-amplify/auth/lib-esm/Auth';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Typography } from '@mui/material';
import { Auth } from 'aws-amplify';
import config from 'aws-exports';
import axios from 'axios';
import classNames from 'classnames';
import useBreakpoints from 'hooks/useBreakpoints';
import { EMAIL_VALIDATION_REGEX } from 'hooks/utils';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { withAuth } from 'state/auth';
import { withProfile } from 'state/profileSteps';
import { BrandRoutes } from 'utils';
import EmailLogin from './components/EmailLogin/EmailLogin';
import OopsPart from './components/OopsPart/OopsPart';
import TootlTipContent from './components/OtpLogin/OtpButtons/components/TooltipContent';
import OTPLogin from './components/OtpLogin/OtpLogin';

const BrandLogin = ({
  setProfileState,
  authState: { email: signedUserEmail },
  getAuth,
}) => {
  const auth = new AuthClass(config);
  auth.configure({
    authenticationFlowType: 'CUSTOM_AUTH',
  });

  const breakpoints = useBreakpoints();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isOtp, setIsOtp] = useState(false);
  const [isError, setIsError] = useState(false);
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(null);

  const onSignIn = async (email: string) => {
    const isValidEmail = EMAIL_VALIDATION_REGEX.test(email);
    if (!isValidEmail) {
      toast.error('Invalid email address');
      return;
    }

    try {
      setIsLoading(true);
      const lowerCaseEmail = email.toLowerCase();
      const cognitoUser = await auth.signIn(lowerCaseEmail, lowerCaseEmail, {
        authenticationFlowType: 'CUSTOM_WITHOUT_SRP',
      });

      setCognitoUser(cognitoUser);
      setEmail(email);
      setIsOtp(true);
      // TODO: move to separate component
      toast(<TootlTipContent />, {
        hideProgressBar: true,
        className: classNames('top-[150px]  bg-[#EDF7ED] h-[80px]', {
          'right-[30px]': breakpoints !== 'sm',
          '-translate-x-1/2 left-[50%] w-[70%]': breakpoints === 'sm',
        }),
        bodyClassName: 'h-full items-start',
        icon: (
          <CheckCircleOutlineIcon className="fill-[#2f7d31]" color="success" />
        ),
      });
    } catch (e) {
      await onUnauthorizedAccess({ email });
    } finally {
      setIsLoading(false);
    }
  };

  const onUnauthorizedAccess = async ({ email }) => {
    try {
      setIsError(true);
      const lambdaUrl = process.env.REACT_APP_EMAIL_LAMBDA_URL;
      if (!lambdaUrl) {
        return;
      }

      await axios.post(lambdaUrl, JSON.stringify({ email }));
    } catch (error) {
      console.error(error, 'Sending Alert Message');
    }
  };

  useEffect(() => {
    if (signedUserEmail) {
      return navigate(BrandRoutes.BrandLoginView);
    }
  }, [signedUserEmail]);

  const onError = async () => {
    setIsError(true);
    setEmail('');
  };

  const onSuccess = useCallback(async () => {
    setIsError(false);
    setIsOtp(false);
    await auth.currentAuthenticatedUser();

    const userData = await auth.currentUserInfo();
    setProfileState((prev) => ({
      ...prev,
      data: userData,
      isLoading: false,
      isLoggedIn: true,
    }));

    const email = userData.attributes?.email;
    if (!email) {
      console.error('Failed to authenticate user ');
      return;
    }

    setEmail(email);
    navigate(BrandRoutes.BrandLoginView);
  }, [navigate, cognitoUser]);

  const onResendOTP = useCallback(async () => {
    await onSignIn(email);
  }, [email]);

  const onConfirmLogin = useCallback(
    async (otp: string) => {
      try {
        if (!cognitoUser) {
          return;
        }

        await Auth.sendCustomChallengeAnswer(cognitoUser, otp);
        getAuth();
        onSuccess();
      } catch (error) {
        onError();
      }
    },
    [cognitoUser, onSuccess]
  );

  const onReset = () => {
    setEmail('');
    setIsError(false);
    setIsOtp(false);
  };

  return (
    <div className="flex flex-col justify-center items-center py-6 px-2 sm:px-6 gap-y-10 min-h-[100vh]">
      <div className="w-full flex items-center">
        <div className="h-[60px] w-[185px] text-left">
          <img src="/images/preview-page-logo.svg" alt="EDCSquared" />
        </div>

        <div className="justify-self-center text-center align-middle mx-auto ">
          <Typography className="uppercase font-bold font-oswald text-[22px] mr-[120px]">
            UNLOCKING THE POWER OF EVERYDAY CONTENT
          </Typography>
        </div>
      </div>

      <div className="sm:border-2 sm:border-gray-main sm:rounded-[16px] h-full w-full flex justify-center items-center py-10 grow  sm:px-2">
        <div className="flex flex-col gap-y-10 min-w-[367px] w-full max-w-[544px]">
          {!isError && !email && (
            <EmailLogin isLoading={isLoading} onLogin={onSignIn} />
          )}
          {!isError && isOtp && (
            <OTPLogin
              onConfirmLogin={onConfirmLogin}
              onCancel={onReset}
              onResend={onResendOTP}
            />
          )}
          {isError && !email && <OopsPart onCancel={onReset} />}
        </div>
      </div>
    </div>
  );
};

export default withProfile(withAuth(BrandLogin));
