import { parsePhoneNumberFromString } from 'libphonenumber-js';

export const usePhoneNumber = (phoneNumber: string) => {
  const phoneNumberData = parsePhoneNumberFromString(phoneNumber || '');
  const countryCode = phoneNumberData?.country?.toLowerCase();

  return {
    countryCode,
  };
};
