import { CreativeRequest } from 'API';

export enum ErrorMessages {
  InvalidFormat = 'File should be in a csv format',
  SelectFile = 'Please select a file',
  InvalidDateRange = 'Invalid date range',
}

export enum SuccessMessages {
  FileSelected = 'File selected',
  Imported = 'Imported successfully',
}

export enum ErrorResponses {
  ImportedEarnings = 'Earnings for ${request.uniqueId} has already been imported',
}

export const SEARCH_ATTRIBUTES = [
  'uniqueId',
  'brandName',
  'email',
] as (keyof CreativeRequest)[];
export const NON_NUMERIC_CHARACTERS_MATCHING_PATTERN = /\D/gi;
