export enum Tabs {
  Manual = 'Manual',
  Meta = 'Meta',
  Tiktok = 'Tiktok',
  Google = 'Google',
}

export enum SuccessMessages {
  Updated = 'Brand activation updated successfully',
}
