import { MenuItem } from '@mui/material';
import { META_CALL_TO_ACTION_LIST } from 'hooks/utils';
import { FC, useMemo } from 'react';

export interface PartialCampaignProps {
  campaign_id?: string | null;
  campaign_name?: string | null;
  campaign_type?: string | null;
}

export interface IMetaOptionsProps {
  campaigns: PartialCampaignProps[] | null | undefined;
}

export const MetaCampaignOptions: FC<IMetaOptionsProps> = ({ campaigns }) =>
  useMemo(
    () =>
      (campaigns || []).map((c, idx) => (
        <MenuItem id={c?.campaign_id || ''} key={idx}>
          {c?.campaign_name}
        </MenuItem>
      )),
    [campaigns]
  );

export interface PartialMetaAdGroupProps {
  adgroup_id?: string | null;
  adgroup_name?: string | null;
  campaign_type?: string | null;
}

export interface IMetaAdGroupsProps {
  adGroups: PartialMetaAdGroupProps[] | null | undefined;
}

export const MetaAdGroupOptions: FC<IMetaAdGroupsProps> = ({ adGroups }) =>
  useMemo(
    () =>
      (adGroups || []).map((c, idx) => (
        <MenuItem id={c?.adgroup_id || ''} key={idx}>
          {c?.adgroup_name}
        </MenuItem>
      )),
    [adGroups]
  );

export const MetaCallToActionOptions = () =>
  useMemo(
    () =>
      META_CALL_TO_ACTION_LIST.map(({ text, value }, idx) => (
        <MenuItem value={value} key={idx}>
          {text}
        </MenuItem>
      )),
    []
  );
