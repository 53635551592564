import { useMutation } from '@apollo/client';
import { LinkApprovedAdsMutation, LinkApprovedAdsMutationVariables } from 'API';
import { getQuery } from 'hooks/utils';
import { linkApprovedAds } from './../../graphql/mutations';

export const UseLinkApprovedAds = () => {
  const [linkAds, { loading, error }] = useMutation<
    LinkApprovedAdsMutation,
    LinkApprovedAdsMutationVariables
  >(getQuery(linkApprovedAds));

  return { linkAds, loading, error };
};
