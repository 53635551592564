import { FormControl, InputLabel, Select } from '@mui/material';
import { BrandBrief } from 'API';
import Switch from 'components/Switch/Switch';
import { StyledTextField } from 'components/TextField';
import {
  getlistAdGroups,
  UseGetTiktokCampaigns,
} from 'hooks/query/useBrandBrief';
import { FC, useCallback, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  CallToActionOptions,
  TiktokAdgroupOptions,
  TiktokCampaignOptions,
  TiktokIdentitiesOptions,
} from './components/TiktokOptions/TiktokOptions';

export interface ITiktokTabProps {
  brandBrief: BrandBrief;
}
const TiktokTab: FC<ITiktokTabProps> = ({ brandBrief }) => {
  const {
    getCampaignList,
    data: campaignData,
    loading: campaignsLoading,
  } = UseGetTiktokCampaigns();
  const {
    getAdGroupList,
    data: adGroupData,
    loading: adGroupsLoading,
  } = getlistAdGroups();
  const { register, getValues, setValue, watch, control } = useFormContext();

  const getCampaignsAndIdentities = useCallback(async () => {
    const tiktokAccess =
      brandBrief.brandProfile?.userProfile?.tiktokAccountAccess;

    if (!tiktokAccess?.access_token || !tiktokAccess.advertiser_id) {
      return;
    }

    await getCampaignList({
      variables: {
        advertiser_id: tiktokAccess.advertiser_id,
        token: tiktokAccess.access_token,
      },
    });
  }, [
    brandBrief.brandProfile?.userProfile?.tiktokAccountAccess,
    getCampaignList,
  ]);

  const getAdGroups = useCallback(async () => {
    const tiktokAccess =
      brandBrief.brandProfile?.userProfile?.tiktokAccountAccess;

    if (!tiktokAccess?.access_token || !tiktokAccess.advertiser_id) {
      return;
    }

    const campaignId = getValues('tiktokData.campaignId');
    if (!campaignId) {
      return;
    }

    await getAdGroupList({
      variables: {
        advertiser_id: tiktokAccess.advertiser_id,
        token: tiktokAccess.access_token,
        campaignId,
      },
    });
  }, [brandBrief]);

  const campaignOptions = TiktokCampaignOptions({
    campaigns: campaignData?.campaigns,
  });
  const identityOptions = TiktokIdentitiesOptions({
    identities: campaignData?.identities,
  });
  const adGroupOptions = TiktokAdgroupOptions({
    adGroups: adGroupData,
  });
  const callToActionProps = CallToActionOptions();

  useEffect(() => {
    if (campaignData == null) {
      getCampaignsAndIdentities();
    }
  }, [campaignData, getCampaignsAndIdentities]);

  useEffect(() => {
    getAdGroups();
  }, [watch('tiktokData.campaignId')]);

  useEffect(() => {
    const identityId = watch('tiktokData.adIdentityId');
    const displayname = campaignData?.identities?.find(
      (i) => i?.identity_id === identityId
    )?.display_name;

    setValue('tiktokData.displayName', displayname);
  }, [watch('tiktokData.adIdentityId')]);

  return (
    <div className="flex flex-col gap-y-4">
      <FormControl>
        <InputLabel size="small" id="tiktok-campaign-id">
          Select TikTok campaign
        </InputLabel>

        <Controller
          name="tiktokData.campaignId"
          control={control}
          render={({ field }) => (
            <Select
              size="small"
              sx={{ maxWidth: '30%' }}
              label="Select TikTok campaign"
              id="tiktok-campaign-id"
              disabled={campaignsLoading || !campaignData}
              {...field}
            >
              {campaignOptions}
            </Select>
          )}
        />
      </FormControl>

      <FormControl>
        <InputLabel size="small" id="tiktok-adIdentity-id">
          {identityOptions?.length
            ? 'Select TikTok Identity'
            : 'TikTok Identity Missing'}
        </InputLabel>

        <Controller
          name="tiktokData.adIdentityId"
          control={control}
          render={({ field }) => (
            <Select
              size="small"
              sx={{ maxWidth: '30%' }}
              label="Select TikTok Identity"
              id="tiktok-adIdentity-id"
              disabled={!campaignData || adGroupsLoading || !identityOptions}
              {...field}
            >
              {identityOptions}
            </Select>
          )}
        />
      </FormControl>

      <FormControl>
        <InputLabel size="small" id="tiktok-adgroup-id">
          {adGroupData?.length
            ? 'Select TikTok ad group'
            : 'TikTok ad group missing'}
        </InputLabel>

        <Controller
          name="tiktokData.adgroupId"
          control={control}
          render={({ field }) => (
            <Select
              size="small"
              sx={{ maxWidth: '30%' }}
              label={'Select TikTok ad group'}
              id="tiktok-adgroup-id"
              disabled={!campaignData || adGroupsLoading || !adGroupData}
              {...field}
            >
              {adGroupOptions}
            </Select>
          )}
        />
      </FormControl>

      <FormControl>
        <InputLabel size="small" id="tiktok-call-to-action">
          Select Call To Action
        </InputLabel>

        <Controller
          name="tiktokData.callToAction"
          control={control}
          render={({ field }) => (
            <Select
              size="small"
              sx={{ maxWidth: '30%' }}
              label="Select Call To Action"
              id="tiktok-call-to-action"
              disabled={!campaignData}
              {...field}
            >
              {callToActionProps}
            </Select>
          )}
        />
      </FormControl>

      <StyledTextField
        size="small"
        sx={{ maxWidth: '30%' }}
        label="Destination URL"
        disabled={!campaignData}
        {...register('tiktokData.landingPageUrl')}
      />

      <Switch
        label="Tiktok spark ads"
        disabled={!campaignData}
        {...register('tiktokData.tikTokSparkAds')}
      />

      <StyledTextField
        size="small"
        sx={{ maxWidth: '30%' }}
        label="Ad caption"
        disabled={!campaignData}
        multiline={true}
        {...register('tiktokData.adCaption')}
      />
    </div>
  );
};

export default TiktokTab;
