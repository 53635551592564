import { Button, CircularProgress } from '@mui/material';
import { InterestedUser } from 'API';
import { Storage } from 'aws-amplify';
import { downloadFileFromUrl } from 'components';
import { UseGetBrandBriefById, UseUpdateBrandBrief } from 'hooks';
import AuthorizeTikTokHandler from 'pages/authorizeTikTok/authorizeTikTokHandler';
import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ProfileContext } from 'state/profileSteps';
import BriefDetailsHeader from './components/BriefDetailsHeader/BriefDetailsHeader';
import CreativeInspirationsDisplay from './components/CreativeInspirationsDisplay/CreativeInspirationsDisplay';
import InformationDisplay from './components/InformationDisplay/InformationDisplay';
import InterestedModal from './components/InterestedModal/InterestedModal';

const CreatorBriefDetails = () => {
  const [searchParams] = useSearchParams();
  const {
    getBrief,
    data: brandBrief,
    loading: briefLoading,
  } = UseGetBrandBriefById();
  const { updateBrief } = UseUpdateBrandBrief();

  const isDownloadDisabled = useMemo(
    () => !brandBrief?.brandBriefFilesUrl,
    [brandBrief?.brandBriefFilesUrl]
  );

  const { profileState } = useContext(ProfileContext);
  const navigate = useNavigate();
  const [isInterestedModalOpen, setIsInterestedModalOpen] = useState(false);
  const [isContentSubmissionModalOpen, setIsContentSubmissionModalOpen] =
    useState(false);

  const getBrandBrief = useCallback(() => {
    const briefId = searchParams.get('id');
    if (!briefId) {
      return;
    }

    getBrief({ variables: { id: briefId } });
  }, [getBrief, searchParams]);

  useEffect(() => {
    const isConfirmation = searchParams.get('isConfirmation') === 'true';
    const needsConfirmation = searchParams.get('needsConfirmation') === 'true';
    const userExistsInList = brandBrief?.interestedUsers?.some(
      (u) => u.id === profileState.data?.id && u.hasBeenShown
    );

    setIsInterestedModalOpen(
      isConfirmation && needsConfirmation && !userExistsInList
    );
  }, [searchParams, brandBrief, profileState.data?.id]);

  useLayoutEffect(() => {
    getBrandBrief();
  }, [getBrandBrief]);

  const handleInterestedness = useCallback(
    async (isInterested: boolean) => {
      if (!brandBrief?.id || !profileState?.data?.id) {
        return;
      }

      const updatedInterestedUsers = Object.values(
        (brandBrief?.interestedUsers || [])
          .map(({ __typename, ...u }) => u)
          .concat([
            { isInterested, hasBeenShown: true, id: profileState.data.id },
          ])
          .reduce((acc, v) => ({ ...acc, [v.id]: v }), {})
      ) as InterestedUser[];

      await updateBrief({
        variables: {
          input: {
            id: brandBrief.id,
            interestedUsers: updatedInterestedUsers,
          },
        },
        errorPolicy: 'ignore',
      });
      setIsInterestedModalOpen(false);
      const nextUrl = searchParams.get('next');
      navigate(nextUrl || '');
    },
    [
      brandBrief?.id,
      brandBrief?.interestedUsers,
      profileState?.data?.id,
      updateBrief,
    ]
  );

  const onFilesDownload = useCallback(async () => {
    if (brandBrief?.brandBriefFilesUrl) {
      const url = await Storage.get(brandBrief.brandBriefFilesUrl);
      downloadFileFromUrl(url);
    }
  }, [brandBrief?.brandBriefFilesUrl]);

  const onCloseContentSubmissionModal = () => {
    setIsContentSubmissionModalOpen(false);
  };

  const onSubmitContent = () => {
    setIsContentSubmissionModalOpen(true);
  };

  return (
    <>
      {briefLoading ? (
        <div className="flex h-full w-full justify-center items-center">
          <CircularProgress className="w-8 h-8 text-main-teal" />
        </div>
      ) : (
        <>
          <div className="w-full h-full flex flex-col gap-6 bg-white">
            <div className="flex lg:gap-x-[30px] gap-x-[20px] xl:gap-y-[0] gap-y-[20px] justify-between flex-wrap">
              <BriefDetailsHeader
                isDownloadDisabled={isDownloadDisabled}
                onSubmitContent={onSubmitContent}
                onDownload={onFilesDownload}
                brandBrief={brandBrief}
              />
            </div>

            <div className="lg:grid grid-cols-2 gap-[30px]">
              <CreativeInspirationsDisplay brandBrief={brandBrief} />

              <InformationDisplay brandBrief={brandBrief} />
            </div>

            <div className="flex justify-center gap-x-[30px]">
              <Button
                disableRipple={true}
                onClick={onSubmitContent}
                className="capitalize bg-[#1D1C1C] font-inter font-[500] text-white text-sm px-[100px] py-[15px] rounded-[6px]"
              >
                Submit Content
              </Button>

              <Button
                disabled={isDownloadDisabled}
                disableRipple={true}
                onClick={onFilesDownload}
                className="capitalize bg-[#1D1C1C] font-inter font-[500] text-white text-sm px-[100px] py-[15px] rounded-[6px] disabled:bg-gray-400"
              >
                Download Files
              </Button>
            </div>
          </div>
        </>
      )}

      {isContentSubmissionModalOpen && (
        <AuthorizeTikTokHandler
          onCross={onCloseContentSubmissionModal}
          briefId={brandBrief?.id || ''}
          BriefName={brandBrief?.BriefName || ''}
          brandName={brandBrief?.brandProfile?.userProfile?.name || ''}
          briefDescription={
            brandBrief?.overview || brandBrief?.brandBriefDetails
          }
          brandImageUrl={''}
          isSparkAds={!!brandBrief?.tikTokData?.tikTokSparkAds}
          disableBackground={true}
        />
      )}

      <InterestedModal
        brandBrief={brandBrief}
        isOpen={isInterestedModalOpen}
        onSubmit={() => handleInterestedness(true)}
        onCancel={() => handleInterestedness(false)}
      />
    </>
  );
};

export default CreatorBriefDetails;
