import { Typography } from '@mui/material';
import { Storage } from 'aws-amplify';
import VideoThumbnail from 'components/VideoThumbnail/VideoThumbnail';
import { FC, useCallback, useLayoutEffect, useMemo, useState } from 'react';
import Pagination from '../../../../components/ui/Pagination';
import { usePagination } from '../../../../hooks/usePagination';
import VideoDisplayModal from '../VideoDisplayModal/VideoDisplayModal';

export interface ICreativeInspirationsProps {
  urls: string[];
}

const CreativeInspirations: FC<ICreativeInspirationsProps> = ({ urls }) => {
  const [signedUrls, setSignedUrls] = useState<string[]>([]);
  const [selectedInspiration, setSelectedInspiration] = useState<string>('');
  const { page, slicedItems, handlePageChange } = usePagination({
    items: urls,
  });

  const getUrls = useCallback(async () => {
    const videoUrls = await Promise.all(
      slicedItems.map((url) => Storage.get(url))
    );
    setSignedUrls(videoUrls);
  }, [slicedItems]);

  const onSelectCreativeInspiration = (url: string) => {
    setSelectedInspiration(url);
  };

  const videoThumbnails = useMemo(
    () =>
      signedUrls.map((url) => (
        <div key={url} onClick={() => onSelectCreativeInspiration(url)}>
          <VideoThumbnail
            thumbnailWrapperClassNames="w-[170px] h-[190px]"
            key={url}
            videoUrl={url}
          />
        </div>
      )),
    [signedUrls]
  );

  useLayoutEffect(() => {
    getUrls();
  }, [getUrls, page, urls]);

  const onCloseModal = () => {
    setSelectedInspiration('');
  };

  return (
    <div className="flex flex-col gap-y-2">
      <Typography className="font-[600] font-oswald text-[20px] uppercase">
        Creative Inspirations
      </Typography>

      <div className="flex flex-col justify-between items-center border-1 border-gray-200 rounded-3xl py-6 px-4 overflow-y-auto">
        <div className="grid grid-cols-3 gap-y-6 gap-x-4">
          {videoThumbnails}
        </div>
        <Pagination
          currentPage={page}
          total={urls.length}
          onPageChange={handlePageChange}
        />
      </div>

      <VideoDisplayModal
        isOpen={!!selectedInspiration}
        videoUrl={selectedInspiration}
        onClose={onCloseModal}
      />
    </div>
  );
};

export default CreativeInspirations;
