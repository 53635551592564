import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { Button } from 'react-bootstrap';

interface ILinkAdsErrorModalProps {
  message: string;
  title: string;
  onClose: () => void;
  isOpen: boolean;
}

const ErrorModal = ({
  message,
  isOpen,
  title,
  onClose,
}: ILinkAdsErrorModalProps) => (
  <Dialog open={isOpen}>
    <DialogTitle sx={{ fontWeight: 600, fontSize: 24, fontFamily: 'Oswald' }}>
      {title}
    </DialogTitle>
    <DialogContent>{message.replace('Error: ', '')}</DialogContent>

    <DialogActions>
      <Button onClick={onClose}>Ok</Button>
    </DialogActions>
  </Dialog>
);

export default ErrorModal;
