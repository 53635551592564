import { ArrowBack } from '@mui/icons-material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Tab } from '@mui/material';
import {
  CreativeRequestEarnings,
  CREATIVE_REQUEST_EARNINGS_BY_CREATOR,
  UserProfile,
} from 'API';
import Modal from 'components/authentication/modal';
import CreatorStatsCard from 'components/creatorStatsCard/creatorStatsCard';
import { StyledDataGrid } from 'components/DataGrid';
import GradientCard from 'components/gradientCard/gradientCard';
import { UseGetCreativeEarnings } from 'hooks';
import { DATA_GRID_ROW_OPTIONS, DEFAULT_PAGE_SIZE } from 'hooks/utils';
import { FC, useEffect, useLayoutEffect, useState } from 'react';
import { withSaveBrief } from 'state/brandBrief';
import { AdminDashboardBoxes, AdminRoutes } from 'utils';
import { ceilToNearest } from '../../utils/utils';
import AddEarningsModal from './addEarningsModal';
import GeneralForm from './components/GeneralForm/GeneralForm';
import ProfileData from './components/ProfileData/ProfileData';
import TableHead from './components/TableHead/TableHead';
import { AdminTabs } from './constants';

type Props = {
  user: UserProfile;
  onReset: () => void;
};
export const EditUser: FC<Props> = ({ user, onReset }) => {
  const [showModal, setShowModal] = useState(false);
  const [tableData, setTableData] = useState<
    CREATIVE_REQUEST_EARNINGS_BY_CREATOR[]
  >([]);
  const [isEarningsModalDisplayed, setIsEarningsModalDisplayed] =
    useState(false);
  const [selectedCreativeEarning, setSelectedCreativeEarning] =
    useState<CreativeRequestEarnings | null>(null);
  const [currentEarnings, setCurrentEarnings] = useState<number>(0);
  const [lifetimeEarnings, setLifetimeEarnings] = useState<number>(0);
  const [previousEarnings, setPreviousEarnings] = useState<number>(0);
  const [value, setValue] = useState(AdminTabs.General);

  const handleChange = (event: React.SyntheticEvent, newValue: AdminTabs) => {
    setValue(newValue);
  };
  const {
    getEarnings,
    loading: earningsLoading,
    data: earningsData,
  } = UseGetCreativeEarnings();

  const calculateTotalEarnings = (earnings) =>
    earnings.reduce(
      (a, v) => ({
        lifetimeEarnings: a.lifetimeEarnings + v.lifetimeEarnings,
        currentEarnings: a.currentEarnings + v.currentEarnings,
        previousEarnings: a.previousEarnings + v.previousEarnings,
      }),
      { lifetimeEarnings: 0, currentEarnings: 0, previousEarnings: 0 }
    );

  useLayoutEffect(() => {
    getEarnings({ variables: { creatorId: user.id } });
  }, []);

  useEffect(() => {
    if (!earningsData.length) {
      return;
    }

    const earnings = earningsData.map((e, idx) => ({
      id: idx,
      creativeRequestId: e?.creativeRequestId || '',
      creativeUniqueId: e?.creativeUniqueId || '',
      earningsCurrent: e?.currentEarnings || 0,
      earningsLifetime: e?.lifetimeEarnings || 0,
      earningsPrevious: e?.previousEarnings || 0,
    }));

    const { lifetimeEarnings, currentEarnings, previousEarnings } =
      calculateTotalEarnings(earningsData);

    setLifetimeEarnings(() => lifetimeEarnings);
    setCurrentEarnings(() => currentEarnings);
    setPreviousEarnings(() => previousEarnings);
    setTableData(earnings as unknown as CREATIVE_REQUEST_EARNINGS_BY_CREATOR[]);
  }, [earningsData]);

  const onAddEarnings = async (earning) => {
    setSelectedCreativeEarning({
      ...earning,
      creativeUniqueId: earning.creativeUniqueId,
    });
    setIsEarningsModalDisplayed(true);
  };

  const tableHead = TableHead({ onAddEarnings });

  const onClose = () => {
    setIsEarningsModalDisplayed(false);
    getEarnings({ variables: { creatorId: user.id } });
  };

  return (
    <div className="flex gap-[20px]">
      <div className="w-[50%] border border-[#F5F1E8] rounded-[16px] py-[25px] px-[10px]">
        <div className="flex justify-between items-center pr-6">
          <h2 className="text-[#0E0D0D] uppercase head-text text-[15px] font-[700] pl-6 m-0">
            Admin - Edit User Details
          </h2>

          <button
            onClick={onReset}
            className="bg-main-black text-white px-[22px] py-[4] w-[160px] h-[42px] rounded-[16px] text-[12px] disabled:bg-neutral-400 break-keep text-wrap font-[600] self-end"
          >
            <div className="flex justify-center gap-x-1 items-center">
              <ArrowBack />
              <div>Back</div>
            </div>
          </button>
        </div>

        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              TabIndicatorProps={{ className: 'bg-[black]' }}
              onChange={handleChange}
            >
              <Tab
                disableRipple
                className="text-[black] head-text font-[700]"
                label={AdminTabs.General}
                value={AdminTabs.General}
              />
              <Tab
                disableRipple
                className="text-[black] head-text font-[700]"
                label={AdminTabs.Profile}
                value={AdminTabs.Profile}
              />
            </TabList>
          </Box>
          <TabPanel value={AdminTabs.General}>
            <GeneralForm user={user} setShowModal={setShowModal} />
          </TabPanel>
          <TabPanel value={AdminTabs.Profile}>
            <ProfileData user={user} />
          </TabPanel>
        </TabContext>
        <div></div>
        <Modal
          content="Great, your Creator Profile changes have been saved!"
          isOpen={showModal}
          type="brand"
          handleClose={() => (window.location.href = AdminRoutes.Creators)}
          actionLabel="Back To Creator users"
          actionHandler={() => (window.location.href = AdminRoutes.Creators)}
        />
      </div>

      <div className="w-[50%]">
        <div className="w-[100%] flex justify-between gap-x-2 mb-3">
          <GradientCard>
            <CreatorStatsCard
              type={AdminDashboardBoxes.CurrentEarnings}
              value={`$${ceilToNearest(currentEarnings)}`}
            />
          </GradientCard>

          <GradientCard>
            <CreatorStatsCard
              type={AdminDashboardBoxes.EarningsPrevious}
              value={`$${ceilToNearest(previousEarnings)}`}
            />
          </GradientCard>

          <GradientCard>
            <CreatorStatsCard
              type={AdminDashboardBoxes.EarningsLifetime}
              value={`$${ceilToNearest(lifetimeEarnings)}`}
            />
          </GradientCard>
        </div>

        <div>
          <StyledDataGrid
            columns={tableHead}
            rows={tableData}
            pageSizeOptions={DATA_GRID_ROW_OPTIONS}
            density="comfortable"
            initialState={{
              pagination: {
                paginationModel: {
                  page: 0,
                  pageSize: DEFAULT_PAGE_SIZE,
                },
              },
            }}
            paginationMode="client"
            loading={earningsLoading}
            disableRowSelectionOnClick={true}
            disableColumnResize={true}
            disableAutosize={true}
            disableColumnMenu={true}
            autosizeOnMount={true}
            disableColumnSelector={true}
            autosizeOptions={{
              expand: true,
              includeHeaders: true,
              includeOutliers: true,
            }}
            onRowClick={({ row }) => onAddEarnings(row)}
          />
        </div>
      </div>

      {isEarningsModalDisplayed && (
        <AddEarningsModal
          userProfileId={user.id}
          getCreativeEarnings={getEarnings}
          earning={selectedCreativeEarning}
          onClose={onClose}
        />
      )}
    </div>
  );
};

export default withSaveBrief(EditUser);
