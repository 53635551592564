import type { ChangeEvent, ForwardedRef } from 'react';
import { forwardRef } from 'react';
import type { RegisterOptions } from 'react-hook-form';
import PhoneInput2, { CountryData, PhoneInputProps } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';

interface Props extends PhoneInputProps {
  label?: string;
  error?: string;
  rules?: RegisterOptions;
  required?: boolean;
  extraProps?: object;
  defaultCountry?: string;
  className?: string;
  onChange?: (
    value: string,
    data: CountryData | object,
    event: ChangeEvent<HTMLInputElement>,
    formattedValue: string
  ) => void;
}

const PhoneInput = forwardRef(
  (
    {
      required = false,
      disabled = false,
      defaultCountry,
      placeholder = 'Input Placeholder',
      label,
      onChange,
      error,
      className,
      extraProps,
      ...props
    }: Props,
    ref: ForwardedRef<HTMLInputElement>
  ) => (
    <label
      className={twMerge(classNames('flex flex-col space-y-[6px]', className))}
    >
      {label && (
        <span
          className={twMerge(
            classNames(
              'transition-all duration-700 text-gray-200 font-medium text-sm',
              {
                ['opacity-60']: disabled,
                ['text-error-100 after:text-error-100']: error,
                ["after:content-['*'] after:text-purple-100"]: required,
              }
            )
          )}
        >
          {label}
        </span>
      )}
      <PhoneInput2
        inputProps={
          ref
            ? {
                ref,
                ...props,
              }
            : { ...props }
        }
        disabled={disabled}
        country={defaultCountry}
        placeholder={placeholder}
        onChange={(...data) => {
          onChange?.(...data);
        }}
        buttonStyle={{
          borderColor: error ? '#C0241A' : '#eaeaea',
          width: 45,
          paddingLeft: 3,
        }}
        inputStyle={{
          width: '100%',
          paddingTop: 25,
          outline: 'none',
          paddingBottom: 23,
          paddingLeft: 50,
          color: error ? '#C0241A' : '#55606A',
          borderColor: error ? '#C0241A' : '#eaeaea',
        }}
        {...extraProps}
      />
      {error && (
        <span
          className={twMerge(
            classNames(
              'transition-all duration-700 text-gray-100 text-xs font-normal h-[5px] text-error-100',
              {
                ['opacity-60']: disabled,
              }
            )
          )}
        >
          {error}
        </span>
      )}
    </label>
  )
);

export default PhoneInput;
