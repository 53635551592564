import { FormControl, InputLabel, Select } from '@mui/material';
import { BrandBrief } from 'API';
import { StyledTextField } from 'components/TextField';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

export interface IGoogleTabProps {
  brandBrief: BrandBrief;
}

const GoogleTab: FC<IGoogleTabProps> = ({ brandBrief }) => {
  const accountAccess =
    brandBrief?.brandProfile?.userProfile?.youtubeAccountAccess;
  // FIXME: remove hard coded value 
  const isDisabled = !accountAccess?.access_token;
  const { register } = useFormContext();

  return (
    <>
      <div className="flex flex-col gap-y-4">
        <FormControl>
          <InputLabel size='small' id="google-campaign-id">
            Select Google campaign
          </InputLabel>

          <Select
            size="small"
            sx={{ width: '30%' }}
            label="Select google campaign"
            disabled={isDisabled}
            {...register('youtubeData.campaignId')}
          ></Select>
        </FormControl>

        <FormControl>
          <InputLabel size='small'>Select google ad group</InputLabel>
          <Select
            size="small"
            sx={{ width: '30%' }}
            label="Select google ad group"
            disabled={isDisabled}
            {...register('youtubeData.adgroupId')}
          ></Select>
        </FormControl>

        <FormControl>
          <InputLabel size='small'>Select google call to action</InputLabel>
          <Select
            size="small"
            sx={{ width: '30%' }}
            label="Select google call to action"
            disabled={isDisabled}
            {...register('youtubeData.callToAction')}
          ></Select>
        </FormControl>

        <StyledTextField
          size="small"
          sx={{ width: '30%' }}
          label="Destination URL"
          disabled={isDisabled}
          {...register('youtubeData.landingPageUrl')}
        />

        <StyledTextField
          size="small"
          rows={6}
          sx={{ width: '30%' }}
          label="Ad caption"
          multiline={true}
          disabled={isDisabled}
          {...register('youtubeData.adData')}
        />
      </div>
    </>
  );
};

export default GoogleTab;
