import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Tab, Typography } from '@mui/material';
import { BrandBrief } from 'API';
import { useState } from 'react';
import { AdminBriefsAccess } from '../AdminBriefAccess/AdminBriefsAccess';
import InterestedTab from './components/InterestedTab/InterestedTab';

interface IAdminBriefDetailsProps {
  brandBrief: BrandBrief;
  getBrandBriefs: () => Promise<void>;
  onBack: () => void;
}

enum AdminBrandBriefDetailTabs {
  ActivationAccess = 'Activation Access',
  Interested = 'INTERESTED Yes / NO',
}

export default ({ brandBrief, getBrandBriefs }: IAdminBriefDetailsProps) => {
  const [tab, setTab] = useState<AdminBrandBriefDetailTabs>(
    AdminBrandBriefDetailTabs.ActivationAccess
  );

  const onChange = (_, tab: AdminBrandBriefDetailTabs) => {
    setTab(tab);
  };

  return (
    <div className="creative-requests">
      <TabContext value={tab}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <TabList
            TabIndicatorProps={{ className: 'bg-orange-main' }}
            onChange={onChange}
          >
            <Tab
              disableRipple={true}
              className={`${tab === AdminBrandBriefDetailTabs.ActivationAccess
                ? 'text-orange-main'
                : 'text-[black]'
                } head-text font-[700] border-b-2 border-b-black border-solid`}
              label={AdminBrandBriefDetailTabs.ActivationAccess}
              value={AdminBrandBriefDetailTabs.ActivationAccess}
            />
            <Tab
              disableRipple={true}
              className={`${tab === AdminBrandBriefDetailTabs.Interested
                ? 'text-orange-main'
                : 'text-[black]'
                } head-text font-[700] border-b-2 border-b-black border-solid`}
              label={AdminBrandBriefDetailTabs.Interested}
              value={AdminBrandBriefDetailTabs.Interested}
            />
          </TabList>

          <Typography
            sx={{
              fontFamily: 'Oswald',
              fontWeight: 700,
              fontSize: 14,
              textTransform: 'uppercase',
            }}
          >
            {brandBrief.brandName} - {brandBrief.BriefName}
          </Typography>
        </Box>

        <TabPanel value={AdminBrandBriefDetailTabs.ActivationAccess}>
          <AdminBriefsAccess
            getBrandBriefs={getBrandBriefs}
            brandBrief={brandBrief}
          />
        </TabPanel>

        <TabPanel value={AdminBrandBriefDetailTabs.Interested}>
          <InterestedTab brandBrief={brandBrief} />
        </TabPanel>
      </TabContext>
    </div>
  );
};
