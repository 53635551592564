import { CAMPAIGNS_RESPONSE, IDENTITIES_RESPONSE } from 'API';
import {
  getlistAdGroups,
  getlistCampaigns,
  getlistMetaAdGroups,
  getlistMetaCampaigns,
} from 'hooks';
import withApolloProvider from 'hooks/apollo/withApollo';
import React, { useContext, useEffect, useState } from 'react';
import { ProfileContext } from 'state/profileSteps';
import { ISelectDropdown, ITikTokCreds } from './brandBrief.interface';

export function withSaveBrief<T>(Component: React.FC<T>): React.FC<T> {
  return withApolloProvider((props: T) => {
    const { profileState } = useContext(ProfileContext);
    const [tiktokCreds, setTiktokCreds] = useState<ITikTokCreds>();

    const {
      getCampaignList,
      data: listCampaignsData,
      loading: campaignListLoading,
    } = getlistCampaigns();

    const {
      getMetaCampaignList,
      data: listMetaCampaigns,
      loading: metaCampaignListLoading,
    } = getlistMetaCampaigns();
    const {
      getAdGroupList,
      data: listAdGroups,
      loading: adGroupsListLoading,
    } = getlistAdGroups();
    const {
      getMetaAdGroupList,
      data: listMetaAdGroups,
      loading: MetaAdGroupsListLoading,
    } = getlistMetaAdGroups();

    const getAdGroups = (campaignId: string): void => {
      getAdGroupList({ variables: { ...tiktokCreds, campaignId } });
    };
    const getMetaAdGroups = (campaignId: string): void => {
      if (profileState.data?.facebookAccountAccess)
        getMetaAdGroupList({
          variables: {
            campaign_id: campaignId,
            access_token: profileState.data?.facebookAccountAccess.access_token,
          },
        });
    };

    useEffect(() => {
      if (profileState?.data) {
        const { tiktokAccountAccess } = profileState.data;
        if (tiktokAccountAccess) {
          try {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const { access_token, advertiser_id } = tiktokAccountAccess;
            if (access_token && advertiser_id) {
              setTiktokCreds({ token: access_token, advertiser_id });
              return;
            }
          } catch (err) {
            console.log(err);
          }
        }
      }
    }, [profileState]);

    useEffect(() => {
      if (tiktokCreds) getCampaignList({ variables: { ...tiktokCreds } });
    }, [tiktokCreds]);

    useEffect(() => {
      if (!listMetaCampaigns && profileState?.data?.facebookAccountAccess) {
        getMetaCampaignList({
          variables: {
            advertiser_id:
              profileState?.data?.facebookAccountAccess.advertiser_id,
            access_token:
              profileState?.data?.facebookAccountAccess.access_token,
          },
        });
      }
    }, [listMetaCampaigns, listCampaignsData]);

    const getFormattedCampaigns = (
      campaigns?: Array<CAMPAIGNS_RESPONSE | null> | null
    ): Array<ISelectDropdown> =>
      (campaigns || []).map((e) => ({
        id: e?.campaign_id || '',
        value: e?.campaign_name || '',
        campaign_type: e?.campaign_type || '',
      }));

    const getFormattedMetaCampaigns = (
      campaigns?: string | null
    ): Array<ISelectDropdown> => {
      if (campaigns) {
        try {
          // TODO: Check and fix
          // eslint-disable-next-line @typescript-eslint/naming-convention
          const { data } = JSON.parse(campaigns);
          if (data?.list?.length) {
            const output = [] as Array<ISelectDropdown>;
            data.list.forEach((e) => {
              output.push({
                id: e.campaign_id,
                value: e.campaign_name,
                campaign_type: e.campaign_type || '',
              });
            });
            return output;
          }
        } catch (err) {
          console.log(err);
        }
      }
      return [];
    };

    const getFormattedAdGroups = (
      adgroups?: string | null
    ): Array<ISelectDropdown> => {
      if (adgroups) {
        try {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          const { data } = JSON.parse(adgroups);
          if (data?.list?.length) {
            const output = [] as Array<ISelectDropdown>;
            data.list.forEach((e) => {
              output.push({
                id: e.adgroup_id,
                value: e.adgroup_name,
                campaign_type: e.campaign_type || '',
              });
            });
            return output;
          }
        } catch (err) {
          console.log(err);
        }
      }
      return [];
    };

    const getFormattedIdentities = (
      identities?: Array<IDENTITIES_RESPONSE | null> | null
    ): Array<ISelectDropdown> =>
      (identities || []).map((i) => ({
        id: i?.identity_id || '',
        value: i?.display_name || '',
        campaign_type: '',
        image: i?.profile_image,
      }));

    const hocProps = {
      getAdGroups,
      getMetaAdGroups,
      dataLoading: campaignListLoading || adGroupsListLoading,
      metaDataLoading: metaCampaignListLoading || MetaAdGroupsListLoading,
      listAdGroups: getFormattedAdGroups(listAdGroups),
      listMetaAdGroups: getFormattedAdGroups(listMetaAdGroups),
      listCampaigns: getFormattedCampaigns(listCampaignsData?.campaigns),
      listMetaCampaigns: getFormattedMetaCampaigns(listMetaCampaigns),
      listIdentities: getFormattedIdentities(listCampaignsData?.identities),
      // response: createData,
    };
    return <Component {...props} {...hocProps} />;
  });
}
export default withSaveBrief;
