import { DocumentNode, gql } from '@apollo/client';
import { apiInitialState } from './constants';
import { ApiCustomHookStateType, GetSuccessResponseType } from './types';

export const getQuery = (query: string): DocumentNode => gql`
  ${query}
`;

export const getSuccessResponse = <T>(data: T): GetSuccessResponseType<T> => {
  return {
    ...apiInitialState,
    data,
    success: true,
    isLoading: false,
  };
};

export function getErrorResponse<T>(
  errorMsg: string
): ApiCustomHookStateType<T> {
  return {
    ...apiInitialState,
    success: false,
    isLoading: false,
    error: errorMsg || 'An error occurred, try again later.',
  };
}

export const getDriveGoogleUrl = (videoUrl: string) => {
  const splitUrl = videoUrl.split('/');
  const fileId = splitUrl[splitUrl.length - 2];

  return fileId ? `https://drive.google.com/uc?id=${fileId}` : '';
};
