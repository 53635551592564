import { Delete, Settings } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { WhitelistEntry } from 'API';

interface IProps {
  entry: WhitelistEntry;
  onDelete: (entry: WhitelistEntry) => void;
  onSettings: () => void;
}

// FIXME: Add tooltip
export const EmailDisplay = ({ entry, onDelete, onSettings }: IProps) => (
  <div className="grow max-w-[350px]">
    <div className="rounded-t-[4px] bg-gray-100 border-b-2 border-gray-300 text-xs">
      <div className="flex justify-between items-center px-4 py-1 font-medium gap-x-2">
        <div className="text-[12px]">{entry.email} </div>

        <div className="flex items-center gap-x-1">
          <IconButton onClick={onSettings}>
            <Settings fontSize="small" />
          </IconButton>

          <IconButton onClick={() => onDelete(entry)}>
            <Delete fontSize="small" />
          </IconButton>
        </div>
      </div>
    </div>
  </div>
);
