import { Download, Edit } from '@mui/icons-material';
import { Button } from '@mui/material';
import { BrandBrief } from 'API';
import { TileTitles } from 'pages/CreatorBriefDetails/constants';
import { FC } from 'react';
import CreatorTile from '../CreatorTile/CreatorTile';

interface IBriefDetailsHeaderProps {
  brandBrief: BrandBrief;
  onDownload: () => Promise<void>;
  onSubmitContent: () => void;
  isDownloadDisabled?: boolean;
}

const BriefDetailsHeader: FC<IBriefDetailsHeaderProps> = ({
  brandBrief,
  onDownload,
  onSubmitContent,
  isDownloadDisabled,
}) => (
  <>
    <CreatorTile
      title={TileTitles.BrandName}
      subtitle={brandBrief?.brandProfile?.userProfile?.name}
    />

    <CreatorTile
      title={TileTitles.BriefName}
      subtitle={brandBrief?.BriefName}
    />

    <CreatorTile
      title={TileTitles.Objective}
      subtitle={brandBrief?.objective}
    />

    <CreatorTile>
      <div className="flex flex-col gap-y-2 2xl:px-[2.7rem] px-[1.5rem]">
        <Button
          className="bg-black text-white font-[400] text-[12px] rounded-[16px] p-2 disabled:bg-gray-400"
          startIcon={<Edit />}
          onClick={onSubmitContent}
        >
          Submit content
        </Button>

        <Button
          className="bg-black text-white font-[400] text-[12px] rounded-[16px] p-2 disabled:bg-gray-400"
          disableRipple={true}
          startIcon={<Download />}
          disabled={isDownloadDisabled}
          onClick={onDownload}
        >
          Download files
        </Button>
      </div>
    </CreatorTile>
  </>
);

export default BriefDetailsHeader;
