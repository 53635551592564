import classNames from 'classnames';
import ShouldRender from 'components/shouldRender';
import { memo, useMemo } from 'react';
import { Spinner } from 'react-bootstrap';

type Props = {
  isLoading: boolean;
  isProfileLoading: boolean;
  videoUrls: string[];
  onDelete: (url: string) => Promise<void>;
  onChoose: (file) => Promise<void>;
  isAutoPlay: boolean;
  fileCount: number;
  totalFiles: number;
  percentage: number;
  error: string;
  profileVideoClasses?: string;
  mainText?: string;
};

export const VideoCarousel = memo(
  ({
    isLoading,
    isProfileLoading,
    videoUrls,
    onDelete,
    isAutoPlay,
    fileCount,
    totalFiles,
    percentage,
    onChoose,
    error,
    profileVideoClasses,
    mainText,
  }: Props) => {
    const profileVideos = useMemo(
      () =>
        videoUrls?.map((url, idx) => (
          <div className="md:col-span-4 sm:col-span-6 col-span-12" key={idx}>
            <div className="relative w-fit m-auto">
              <div
                className=" absolute top-[3px] right-[4px] cursor-pointer z-[9]"
                onClick={() => onDelete(url)}
              >
                <img
                  className="h-[20px] w-[20px]"
                  src="images/remove.png"
                  alt=""
                />
              </div>
              <video
                key={url}
                controls
                className="outline-none h-[264px] object-contain rounded-[16px] m-auto xl:max-w-full max-w-[175px]"
                autoPlay={isAutoPlay}
                playsInline
                muted
              >
                <source src={url} type="video/mp4" />
              </video>
            </div>
          </div>
        )),
      [JSON.stringify(videoUrls)]
    );

    return (
      <div
        className={`border border-[#F5F1E8] rounded-[16px] bg-[#1D1C1C] flex flex-col justify-between`}
      >
        <div>
          <div className="text-[#fff] uppercase mt-[31px] ml-[28px] mb-[50px] head-text text-[14px] flex items-center font-[700]">
            <h6 className="pl-[12px]">
              {mainText || 'Your content portfolio'}
            </h6>
          </div>
          {!isLoading ? (
            <>
              {isProfileLoading && !videoUrls ? (
                <div className="lg:h-[511px] h-[321px] flex justify-center items-center">
                  <Spinner />
                </div>
              ) : (
                <div className="flex relative justify-center lg:min-h-[511px] min-h-[268px] px-[60px]">
                  <div className="relative w-full">
                    {videoUrls?.length ? (
                      <div
                        className={classNames(profileVideoClasses, {
                          'grid grid-cols-12 gap-[18px] md:mb-0 mb-[20px]':
                            !profileVideoClasses,
                        })}
                      >
                        {profileVideos}
                      </div>
                    ) : (
                      <div className="lg:h-[544px] h-[268px] flex justify-center items-center text-white head-text font-[700] text-[16px] uppercase">
                        No profile content uploaded
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="lg:h-[511px] h-[321px] flex justify-center items-center">
              <div className="flex flex-col items-center">
                <h6 className="text-[#fff] mb-[15px] head-text text-[16px] flex items-center font-[700]">
                  Uploaded files count {fileCount} / {totalFiles}
                </h6>

                <div
                  className={`${
                    percentage > 0 ? 'border border-white' : ''
                  } h-[48px] w-[48px] rounded-full flex justify-center items-center`}
                >
                  {percentage > 0 ? (
                    <div className="font-bold text-white">{percentage}%</div>
                  ) : (
                    <Spinner
                      animation="border"
                      className="spinner-border"
                      variant="primary"
                    />
                  )}
                </div>
              </div>
            </div>
          )}

          <ShouldRender if={error}>
            <div className="lg:h-[544px] h-[321px] flex justify-center items-center text-[#c22828] head-text font-[700] text-[16px] uppercase">
              {error}
            </div>
          </ShouldRender>
        </div>
        <input
          id="file-upload"
          type="file"
          className="hidden"
          multiple
          accept="video/*, .mov"
          onChange={(e) => onChoose(e.target.files)}
        />
        <label className="flex justify-center mb-[70px]">
          <label
            htmlFor="file-upload"
            className="manageProfile-btn cursor-pointer"
          >
            <h6>Manage Profile content</h6>
          </label>
        </label>
      </div>
    );
  }
);
