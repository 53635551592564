import { NUMERIC_INPUT_VALIDATION_REGEX, OTP_LENGTH } from 'hooks/utils';
import { useEffect, useRef, useState } from 'react';
import OtpButtons from './OtpButtons/OtpButtons';

interface IOTPLoginProps {
  onConfirmLogin: (otp: string) => Promise<unknown>;
  onCancel: () => void;
  onResend: () => Promise<void>;
}

const OTPLogin = ({
  onConfirmLogin,
  onCancel,
  onResend,
}: IOTPLoginProps): JSX.Element => {
  const [otpSymbols, setOTPSymbols] = useState(new Array(OTP_LENGTH).fill(''));
  const inputsRef = useRef<HTMLInputElement[] | null[]>([]);

  const isInputsFilled = otpSymbols.every((el) => el);
  const trySetFocusTo = (num: number): void => {
    if (num > OTP_LENGTH - 1 || num < 0)
      return inputsRef.current[num - 1]?.blur();
    inputsRef.current[num]?.focus();
  };

  const setAllOtpInputs = (input: string): void => {
    setOTPSymbols(input.split(''));
    onConfirmLogin(input);
    inputsRef.current?.forEach((el) => el.blur());
  };

  const handleChange = async (
    { value }: EventTarget & HTMLInputElement,
    index: number
  ) => {
    if (value.length === OTP_LENGTH) return setAllOtpInputs(value);
    if (value.length > 1) return trySetFocusTo(index + 1);
    if (!NUMERIC_INPUT_VALIDATION_REGEX.test(value) || !inputsRef.current)
      return;

    setOTPSymbols((prev) => {
      prev[index] = value;
      return [...prev];
    });
    trySetFocusTo(index + 1);
    const confirmationOTP = [...otpSymbols, value].filter((s) => s).join('');
    confirmationOTP.length === OTP_LENGTH && onConfirmLogin(confirmationOTP);
  };

  const handleBackspace = (event, index) => {
    if (!inputsRef.current) return;
    if (event.key === 'Backspace') {
      event.preventDefault();
      setOTPSymbols((prev) => {
        prev[index] = '';
        return [...prev];
      });
      trySetFocusTo(index - 1);
    }
  };

  useEffect(() => {
    if (!inputsRef.current) return;
    inputsRef.current[0]?.focus();
  }, []);

  return (
    <div className="flex flex-col gap-10 w-full bg-white shadow-xl p-6 rounded-[10px]">
      <h3 className="font-oswald font-semibold text-[18px] uppercase">
        Enter OTP
      </h3>

      <div className="flex gap-2 self-center">
        {otpSymbols.map((value, index) => (
          <input
            disabled={isInputsFilled}
            onChange={(e) => handleChange(e.target, index)}
            onKeyDown={(e) => handleBackspace(e, index)}
            ref={(el) => (inputsRef.current[index] = el)}
            className={
              'border-1 text-center disabled:bg-gray-100 border-black rounded-[5px] p-2 w-[40px] h-[40px]'
            }
            value={value}
            key={index}
          />
        ))}
      </div>

      <OtpButtons
        isDisabled={isInputsFilled}
        onResend={onResend}
        onCancel={onCancel}
      />
    </div>
  );
};

export default OTPLogin;
