import { Add, Edit, OpenInNew, Search } from '@mui/icons-material';
import { debounce, IconButton, InputAdornment, Switch } from '@mui/material';
import { GridPaginationModel } from '@mui/x-data-grid';
import { BrandBrief } from 'API';
import {
  renderCustomDataGridTableHeaderCell,
  StyledDataGrid,
} from 'components/DataGrid';
import { Tdata } from 'components/table/Table';
import { StyledTextField } from 'components/TextField';
import Modal from 'components/ui/modal';
import { editCampaignBrief, getBrandBriefList } from 'hooks';
import {
  DEFAULT_DEBOUNCE_TIME_IN_MILLISECONDS,
  DEFAULT_PAGE_SIZE,
} from 'hooks/utils';
import CampaignBriefDetails from 'pages/campaignBriefDetails/campaignBriefDetails';
import { FC, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BrandBriefProps,
  GetBrandBriefsPayload,
  withBrandBriefs,
} from 'state/brandBrief';
import { AuthRoutes, BrandRoutes } from 'utils';
import { BRIEF_TABLE_COL_DEF } from './constants';

const getFormattedData = (bb: BrandBrief) => ({
  ...bb,
  id: bb?.id,
  img: bb.brandProfile?.userProfile?.avatar || '/images/default-image.png',
  activationName: bb?.BriefName,
  brandName: bb?.brandProfile?.userProfile?.name,
  details: bb?.brandBriefDetails,
  objective: bb?.objective,
  country: bb?.country,
  status: bb?.active ? 'Active' : 'In-active',
});

export const BrandBriefs: FC<BrandBriefProps> = ({
  profileState,
  requests,
}) => {
  const [selectedBrief, setSelectedBrief] = useState<BrandBrief | null>(null);
  const [search, setSearch] = useState('');
  const [briefsTableData, setBriefsTableData] = useState<Tdata[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [verticalError, setVerticalError] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [rowsCount, setRowsCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const { data, loading, getBrandBriefslist } = getBrandBriefList();
  const { editBrief } = editCampaignBrief();

  const getBrandBriefs = ({
    page,
    pageSize,
    search,
  }: GetBrandBriefsPayload) => {
    const brandId = profileState?.brand?.items?.[0]?.id;
    if (!brandId) {
      return;
    }

    return getBrandBriefslist({
      variables: { brandId, page, pageSize, search },
    });
  };

  useLayoutEffect(() => {
    getBrandBriefs({ page, pageSize });
  }, []);

  // FIXME: refine the types
  const onDetails = (brief: BrandBrief, event?: any) => {
    const value = event?.target?.value;
    const isSwitch = value === 'on' || value === 'off';
    if (isSwitch || !brief) {
      return;
    }

    setSelectedBrief(brief);
    navigate(`${AuthRoutes.BrandBrief}?brief=true`);
  };

  const onEditBrief = (brief: BrandBrief) => {
    navigate(BrandRoutes.EditBrief, {
      state: {
        brief,
      },
    });
  };

  const onBriefStatusChange = (brief: BrandBrief) => {
    const update = debounce(async () => {
      setIsLoading(true);
      await editBrief({
        variables: { input: { id: brief.id, active: !brief.active } },
        errorPolicy: 'ignore',
      });

      await getBrandBriefs({ page, pageSize });
      setIsLoading(false);
    }, 200);

    return update();
  };

  const tableHead = useMemo(() => {
    const header = [...BRIEF_TABLE_COL_DEF].map((cd) => {
      switch (cd.field) {
        case 'details':
          return {
            ...cd,
            renderCell: (params) => (
              <IconButton
                onClick={() => {
                  onDetails(params.row);
                }}
              >
                <OpenInNew />
              </IconButton>
            ),
          };
        case 'edit':
          return {
            ...cd,
            renderCell: (params) => (
              <IconButton
                onClick={() => {
                  onEditBrief(params.row);
                }}
              >
                <Edit />
              </IconButton>
            ),
          };
        case 'status':
          return {
            ...cd,
            renderCell: ({ row }) => {
              return (
                <Switch
                  defaultChecked={row.active}
                  onChange={() => onBriefStatusChange(row)}
                />
              );
            },
          };
        default:
          return cd;
      }
    });

    return renderCustomDataGridTableHeaderCell(header, 'font-bold');
  }, []);

  const onSearch = debounce(async (search: string) => {
    getBrandBriefs({
      page: 0,
      pageSize: DEFAULT_PAGE_SIZE,
      ...(search && { search: search.trim() }),
    });

    setPage(0);
  }, DEFAULT_DEBOUNCE_TIME_IN_MILLISECONDS);

  const checkParam = () => {
    const url = new URL(window.location.href);
    return !!url.searchParams.get('brief');
  };

  useEffect(() => {
    if (!data) {
      return;
    }
    const tableData = (data?.items || []).map(getFormattedData);

    setRowsCount(data?.totalItems || 0);
    setBriefsTableData(tableData);
  }, [data]);

  useEffect(() => {
    if (!selectedBrief) {
      navigate(AuthRoutes.BrandBrief);
    }
  }, []);

  const onPageChange = (model: GridPaginationModel) => {
    const payload =
      model.pageSize !== pageSize
        ? { page: 0, pageSize: model.pageSize }
        : { page: model.page, pageSize };

    setPage(payload.page);
    setPageSize(payload.pageSize);
    return getBrandBriefs({
      ...payload,
      ...(search && { search: search }),
    });
  };

  return (
    <>
      {selectedBrief && checkParam() ? (
        <CampaignBriefDetails
          id={profileState?.id}
          data={selectedBrief}
          hashtags={profileState?.hashtags || []}
          description={profileState?.description || ''}
          requests={requests}
          userType={profileState?.userType || ''}
          onBack={() => setSelectedBrief(null)}
        />
      ) : (
        <div className="flex flex-col gap-4">
          <Modal
            title=""
            isOpen={showModal}
            handleClose={() => {
              setShowModal(false);
              setVerticalError(false);
            }}
          >
            {verticalError && (
              <>
                <div className="w-full text-center">
                  <h2 className="head-text font-[700] md:text-[24px] text-[18px] text-[#0E0D0D]">
                    Please select a vertical before creating a brand activation
                  </h2>
                </div>
                <div className="w-full flex justify-center text-white mt-5">
                  <button
                    className="creator-button text-[16px] mb-5 mt-3"
                    onClick={() => navigate(AuthRoutes.EditProfile)}
                  >
                    Profile
                  </button>
                </div>
              </>
            )}
          </Modal>

          <section className="mt-2 flex sm:gap-4 sm:items-center sm:flex-row flex-col-reverse">
            <div className="w-full flex justify-between items-center mt-4">
              <div className="w-[220px]">
                <StyledTextField
                  variant="standard"
                  placeholder="Search..."
                  onChange={(event) => {
                    setSearch(event.target.value);
                    onSearch(event?.target.value);
                  }}
                  fullWidth={true}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>

            <div
              data-cy="create-brief"
              onClick={() => {
                if (!profileState?.vertical) {
                  setVerticalError(true);
                  setShowModal(true);
                  return;
                }

                navigate(BrandRoutes.CreateBrief);
              }}
            >
              <button className="bg-main-black text-white px-[22px] py-[4] w-[160px] h-[42px] rounded-[16px] text-[15px]">
                <div className="flex justify-evenly uppercase font-semibold items-center gap-x-1">
                  <Add />
                  <div>Activation</div>
                </div>
              </button>
            </div>
          </section>

          <section className="grid-cols-1">
            <div className="mih-h-[400px] max-w-[100%] overflow-x-visible">
              <StyledDataGrid
                sx={{
                  minHeight: 600,
                }}
                density="comfortable"
                rows={briefsTableData || []}
                columns={tableHead}
                initialState={{
                  pagination: {
                    paginationModel: { page, pageSize },
                  },
                }}
                onRowClick={(params, event) => onDetails(params.row, event)}
                rowCount={rowsCount}
                onPaginationModelChange={onPageChange}
                paginationMode="server"
                pageSizeOptions={[5, 10]}
                disableColumnResize={true}
                disableRowSelectionOnClick={true}
                disableAutosize={true}
                disableColumnMenu={true}
                autosizeOnMount={true}
                disableColumnSelector={true}
                loading={loading || isLoading}
                autosizeOptions={{
                  expand: true,
                  includeHeaders: true,
                  includeOutliers: true,
                }}
              />
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default withBrandBriefs(BrandBriefs);
