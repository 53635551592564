import { Auth } from 'aws-amplify';
import { FC, Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { withProfile } from 'state/profileSteps';
import {
  AuthProps,
  initialAuthContext,
  ProfileProps,
  UnAuthRoutes,
} from 'utils';
import { withAuth } from '../../state/auth';

export const LogoutPage: FC<ProfileProps & AuthProps> = ({
  cleanProfileState,
  setAuthState,
}) => {
  const navigate = useNavigate();

  const logUserOut = async (): Promise<void> => {
    await Auth.signOut();
    setAuthState({ ...initialAuthContext });
    cleanProfileState();
    navigate(UnAuthRoutes.Login);
  };

  useEffect(() => {
    logUserOut();
  }, []);

  return <Fragment />;
};

export default withAuth(withProfile(LogoutPage));
