import { Typography } from '@mui/material';

const ComingSoon = () => {
  return (
    <div className="sm:px-2 px-4 text-center align-center">
      <Typography variant="h3" className="font-oswald font-bold uppercase">
        Coming soon
      </Typography>
    </div>
  );
};

export default ComingSoon;
